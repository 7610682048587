import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import AppModal from '../../../components/modals';
import useAuth from '../../../utils/hooks/use-auth';

function ScoreCard({ cardToggle, yoyoData }) {
  const [openVideo, setOpenVideo] = useState(false);
const {userDetails} = useAuth()
const yoyoAttempts = userDetails?.TotalYoyoAttempt.toString().length == 1 ? "0"+userDetails?.TotalYoyoAttempt :  userDetails?.TotalYoyoAttempt.toString()
  return (
    <>
      <div
        className={cardToggle ? 'score-card px-7 active' : 'score-card px-7'}>
        <Form className="text-capitalize">
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="pb-1">My level</Form.Label>
            <div className="form-field">{yoyoData?.YoYoLevel}</div>
          </Form.Group>
          <Form.Group className="pt-md-10 pt-6" controlId="formBasicPassword">
            <Form.Label className="d-block">total time taken</Form.Label>
            {yoyoData?.TotalTimeTaken?.split('')?.map((v, i) => {
              if (i === 2)
                return (
                  <span className="mr-2 form-label" key={i}>
                    :
                  </span>
                );
              return (
                <span className="form-field time mr-2" key={i}>
                  {v}
                </span>
              );
            })}
          </Form.Group>
          <Form.Group className="pt-md-10 pt-6" controlId="formBasicPassword">
            <Form.Label className="d-block">No. of shuttles covered</Form.Label>
            {yoyoData?.TotalShuttle?.split('')?.map((v, i) => {
              return (
                <span className="form-field time mr-2" key={i}>
                  {v}
                </span>
              );
            })}
          </Form.Group>

          <Form.Group className="pt-md-10 pt-6" controlId="formBasicPassword">
            <Form.Label className="d-block">No. of attempts</Form.Label>
            {yoyoAttempts?.split('')?.map((v, i) => {
              return (
                <span className="form-field time mr-2" key={i}>
                  {v}
                </span>
              );
            })}
          </Form.Group>

          <Form.Group className="pt-md-10 pt-6" controlId="formBasicPassword">
            <Form.Label className="pb-1">My Yo-Yo test video</Form.Label>
            {
              // yoyoData?.VideoUrl}
            }
            <Button
              className="btn btn-outline d-flex p-0 align-items-center"
              onClick={() => setOpenVideo(true)}>
              <span className="px-3 text-capitalize">Play Video</span>{' '}
              <img src="/assets/img/icons/Play-icon.png" alt="Video Button" />
            </Button>
          </Form.Group>
        </Form>
      </div>
      <AppModal
        show={openVideo}
        onClose={() => setOpenVideo(false)}
        bodyClass="p-0 w-100 mw-100"
        headerClass="d-none"
        contentClassName="p-0 m-0">
        <div className="d-flex justify-content-end mb-0">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setOpenVideo(false)}
            style={{ right: 0 }}></button>
        </div>
        <video
          src={yoyoData?.VideoUrl}
          autoPlay
          controls
          className="w-100 rounded d-block"
          playsInline
          webkit-playsInline
        />
      </AppModal>
    </>
  );
}

export default ScoreCard;
