import React from "react";
import AppModal from "../../components/modals";
import usePopup from "../../utils/hooks/use-popup";

function YoYoEntrySuccess({ show, onClose }) {
  const { openGenerateARCertificatePopup } = usePopup();
  return (
    <div>
      <AppModal
        show={show}
        onClose={onClose}
        bodyClass="max-w-none"
        heading="Entry Successfully Submitted!"
      >
        <div className="row justify-content-center">
          <div className="col-10">
            <p className="mt-2">
              We’re evaluating it and will be revealing the results soon.
            </p>
            <h6 className="mt-8">
              Meanwhile, get your Yo-Yo Test certificate with
              <span className="text-primary"> Neeraj Chopra</span>
            </h6>

            <img src="/assets/img/common/certificate.png" alt="Certificate" />

            <h6 className="my-4">Just smile and snap!</h6>
            <button
              id="captureButton"
              className="btn btn-primary btn-block"
              onClick={() => {
                onClose();
                openGenerateARCertificatePopup(true);
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </AppModal>
    </div>
  );
}

export default YoYoEntrySuccess;
