import React, { useCallback, useEffect, useState } from 'react';
import useLoader from '../../utils/hooks/use-loader';
import {
  CultfitLeaderBoardData,
  GetLeaderBoarddata,
} from '../../services/winner-service';
import { Button, Col, Row } from 'react-bootstrap';
import { ReactSelectInput } from '../../utils/form-inputs';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useAuth from '../../utils/hooks/use-auth';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

function LeaderBoard() {
  const { toggleLoader } = useLoader();
  const { userDetails } = useAuth();
  const navigate = useNavigate()
  const [winnerList, setWinnerList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(10); // Default page size, can be modified as needed
  const [totalRecords, setTotalRecords] = useState(0);
  const levelDetail = useSelector((state) => state.common.level);
  const { control, watch,setValue } = useForm({
    defaultValues: {
      Level: levelDetail?.level,
    },
  });

  console.log(levelDetail, 'levelDetail');
  const level = watch('Level');
  const fetchWinnerList = useCallback(async () => {
    toggleLoader();
    try {
      const reqData = {
        Id: userDetails?.Id,
        PageNumber: pageNo ? pageNo : null,
        Yoyolevel: level,
      };

      const res =
        levelDetail?.LeaderBoardRoute !== 'cultfit-onground'
          ? await GetLeaderBoarddata(reqData)
          : await CultfitLeaderBoardData(reqData);

      if (res.status === 200) {
        const data = res?.data;
        const WinnerList = data?.Data?.DataList;

        const TotalRow = data?.Data?.TotalCount;
        if (WinnerList?.length) {
          setWinnerList(WinnerList);
          setTotalRecords(TotalRow);
        }
      }
    } catch (err) {
      console.log(err, 'err');
      // Handle error
      setWinnerList([]);
      setTotalRecords([]);
    } finally {
      toggleLoader(false);
    }
  }, [pageNo, level]);

  useEffect(() => {
    fetchWinnerList();
  }, [fetchWinnerList]);

  const handlePrevious = () => {
    if (pageNo > 1) {
      setPageNo((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    const maxPage = Math.ceil(totalRecords / pageSize);
    if (pageNo < maxPage) {
      setPageNo((prevPage) => prevPage + 1);
    }
  };
  //go back button 
  const goBack = () => {
    if (levelDetail && levelDetail?.LeaderBoardRoute === "on-college") {
      navigate('/on-college');
    } else if (levelDetail && levelDetail?.LeaderBoardRoute === "cultfit-onground") {
      navigate('/cultfit-onground')
    } else {
      window.history.back();
    }
  };
  useEffect(() => {
    if (winnerList?.length) {
      winnerList?.map((w) => {
        return (
          setValue("Level", w.YoyoLevel)
        )
      })
    }
  }, [winnerList?.length])
  return (
    <>
      {levelDetail?.LeaderBoardRoute !== 'cultfit-onground' && (
        <div className="mx-auto mt-8 mb-md-1" style={{ maxWidth: '258px' }}>
          <ReactSelectInput
            control={control}
            name="Level"
            options={[
              { label: 'Beginner', value: 'Beginner' },
              { label: 'Intermediate', value: 'Intermediate' },
              { label: 'Advanced', value: 'Advanced' },
            ]}
          />
        </div>
      )}
      {winnerList.length === 0 ? (
        <h3 className="justify-content-center text-center mt-14 text-primary">
          No data found.
        </h3>
      ) : (
        <Row className="justify-content-center text-center">
          <Col xs={12} md={10}>
            <div className="winner-wrapper">

              <div className="winner-list-scroller">
                <>
                  <ul className="winner-list">
                    <li className="winner-list-item winner-list-item-header">
                      <span>Rank</span>
                      <span>Name</span>
                      <span>Mobile No.</span>
                      <span>Time Taken</span>
                    </li>
                    {winnerList?.map((data, index) => (
                      <li className="winner-list-item">
                        <span> {data.Rank ? data.Rank : 'N/A'}</span>
                        {/* {(pageNo - 1) * pageSize + index + 1} */}
                        <span className="text-truncate" title={data.Name}>
                          {data.OrganizerName ? data.OrganizerName : 'N/A'}
                        </span>

                        <span>{data?.MobileNo ? data?.MobileNo : 'N/A'}</span>

                        <span>
                          {data?.TotalTimeTaken ? data?.TotalTimeTaken : 'N/A'}
                        </span>
                      </li>
                    ))}
                  </ul>
                  {totalRecords > 10 && (
                    <div className="d-flex justify-content-center view-link">
                      <Button
                        variant="link"
                        size="sm"
                        className="link mt-5"
                        onClick={handlePrevious}
                        disabled={pageNo === 1}>
                        Prev
                      </Button>
                      <Button
                        variant="link"
                        size="sm"
                        onClick={handleNext}
                        disabled={pageNo === Math.ceil(totalRecords / pageSize)}
                        className="link mt-5">
                        Next
                      </Button>
                    </div>
                  )}
                </>
              </div>
            </div>
            <p className="mt-5">
              <Link className="text-center" onClick={() => goBack()}>Try again</Link>
            </p>
          </Col>
        </Row>
      )}
    </>
  );
}

export default LeaderBoard;
