import React from "react";
import TestForm from "../../features/college-leg/college-leg-test-form";

const OnGroundAdminForm = () =>{
    return(
        <>
        <div className="d-flex justify-content-center mt-8">
        <TestForm route="on-ground-admin"/>
      </div>
        </>
    )
}

export default OnGroundAdminForm;