export const API_END_POINT = {
  // auth and user endpoints
  sendOtp: "/User/SendOTP",
  verifyOTP: "/User/VerifyOTP",
  registerUser: "/User/RegisterUser",
  getUserById: "/User/GetUserById",
  updateUser: "/User/UpdateUser",
  updateUserName: "/User/UpdateName",
  updateUserEmail: "/User/UpdateEmail",
  updateProfileImg: "/User/UpdateProfileImg",

  // media
  mediaUpload: "/User/MediaUpload",

  // Fitness tracker endpoints
  verifyUniqueCode: "/FitnessTracker/VerifyUniqueCode",
  getUserClaimTimeById: "/FitnessTracker/GetUserClaimTimeById",
  claimNowFitnessTracker: "/FitnessTracker/ClaimNowFitnessTracker",
  checkQuota: "/FitnessTracker/CheckQuota",

  // YOYO
  registerYoYoTest: "/YoYoTest/RegisterYoYoTest",
  getYoYoTestById: "/YoYoTest/GetYoYoTestById",
  generateARCertificate: "/YoYoTest/GenerateARCertificate",
  claimNowYoYoTicket: "/YoYoTest/ClaimNowICCTicket",

  // On College
  registerOnCollegeYoYoTest: "/OngroundYoYoTest/RegisterOnGroundYoYoTest",

  // On Ground
  registerOnGroundYoYoTest: "/OngroundYoYoTest/RegisterOnGroundYoYoTest",

  //On cult fit
  registerOnCultfitOnGround:
    "/Cultfit_OngroundYoYoTest/RegisterCultfitOnGroundYoYoTest",

  //Winner
  getAllWinner: "/User/GetAllWinner",

  //venu listing
  GetCultfitVenue: "/Cultfit_OngroundYoYoTest/GetCultfitVenue",

  //leader board list
  GetCultfitLeaderBoarddata:
    "/Cultfit_OngroundYoYoTest/GetCultfitLeaderBoarddata",

  GetOngroundVenue: "/OngroundYoYoTest/GetOngroundVenue",

  GetLeaderBoarddata: "/OngroundYoYoTest/GetLeaderBoarddata",

  //admin reports
  GetCultOnGroundDataList: "/DataGathering/GetCultOnGroundDataList",
  GetOnGroundDataList: "/DataGathering/GetOnGroundDataList",
  GetEmployeeOnGroundDataList: "/DataGathering/GetEmployeeOnGroundDataList",
  GetFitbitDataList: "/DataGathering/GetFitbitDataList",
  GetYoyoticketDataList: "/DataGathering/GetYoyoticketDataList",

  //pull unique code request 
  pullUniqueCode:"/PullNotification/GetuniqueCode",
  RegisterAdminOnGroundYoYoTest:"/AdminOngroundTest/RegisterAdminOnGroundYoYoTest"
};
