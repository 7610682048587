export * from "./api-end-points";
export * from "./common-constants";
export * from "./validation-constants";
export * from "./validation-regex";

export const winnerListDummydata = {
  DataList: [
    {
      Id: 1,
      Name: "Bharat",
      ProfileImg: "",
      PrizeWon: "Ticket",
    },
    {
      Id: 2,
      Name: "",
      ProfileImg: "",
      PrizeWon: "Ticket",
    },
    {
      Id: 3,
      Name: "",
      ProfileImg: "",
      PrizeWon: "Ticket",
    },
    {
      Id: 4,
      Name: "Akshit Ar",
      ProfileImg: "",
      PrizeWon: "Ticket",
    },
    {
      Id: 5,
      Name: "Abc",
      ProfileImg: "",
      PrizeWon: "Ticket",
    },
    {
      Id: 6,
      Name: "Akshit Arora",
      ProfileImg: "",
      PrizeWon: "Ticket",
    },
    {
      Id: 7,
      Name: "Abcdef",
      ProfileImg: "",
      PrizeWon: "Ticket",
    },
    {
      Id: 8,
      Name: "Nitin Sukhija",
      ProfileImg: "",
      PrizeWon: "Fitbit",
    },
    {
      Id: 9,
      Name: "vipul",
      ProfileImg: "",
      PrizeWon: "Fitbit",
    },
    {
      Id: 10,
      Name: "Bharat",
      ProfileImg: "",
      PrizeWon: "Ticket",
    },
    {
      Id: 11,
      Name: "ssss",
      ProfileImg: "",
      PrizeWon: "Ticket",
    },
    {
      Id: 12,
      Name: "hhhh",
      ProfileImg: "",
      PrizeWon: "Ticket",
    },
    {
      Id: 13,
      Name: "Akshit Ar",
      ProfileImg: "",
      PrizeWon: "Ticket",
    },
    {
      Id: 14,
      Name: "Abc",
      ProfileImg: "",
      PrizeWon: "Ticket",
    },
    {
      Id: 15,
      Name: "Akshit Arora",
      ProfileImg: "",
      PrizeWon: "Ticket",
    },
    {
      Id: 16,
      Name: "Abcdef",
      ProfileImg: "",
      PrizeWon: "Ticket",
    },
    {
      Id: 17,
      Name: "Nitin Sukhija",
      ProfileImg: "",
      PrizeWon: "Fitbit",
    },
    {
      Id: 18,
      Name: "shweta",
      ProfileImg: "",
      PrizeWon: "Fitbit",
    },
  ],
  TotalCount: 18,
};

export const commonColumns = [
  {
    name: "Ranks",
    selector: (tableData) => tableData?.["Ranks"] || "NA",
    wrap: true,
    // cell: (row) => {
    //   return <a href={`/tradeDetail/${row.Id}`}>{row.Id}</a>;
    // },
  },
  {
    name: "Name",
    selector: (tableData) => tableData?.["RegisterName"] || "NA",
  },
  {
    name: "MobileNo",
    selector: (tableData) => tableData?.MobileNo || "NA",
    wrap: true,
  },
  // {
  //   name: "Email",
  //   selector: (tableData) => tableData?.Email || "NA",
  //   wrap: true,
  // },
  {
    name: "City",
    selector: (tableData) => tableData?.City || "NA",
    wrap: true,
  },

  {
    name: "Shuttles",
    selector: (tableData) => tableData?.Shuttles || "NA",
    wrap: true,
  },
  {
    name: "TotalTimeTaken",
    selector: (tableData) => tableData?.TotalTimeTaken || "NA",
    wrap: true,
  },
  {
    name: "Venue",
    selector: (tableData) => tableData?.Venue || "NA",
    wrap: true,
  },
  {
    name: "YoyoLevel",
    selector: (tableData) => tableData?.YoyoLevel || "NA",
    wrap: true,
  },
];

export const fitBitColumns = [
  {
    name: "Name",
    selector: (tableData) => tableData?.Name || "NA",
    wrap: true,
  },
  {
    name: "Address",
    selector: (tableData) => tableData?.Address || "NA",
    wrap: true,
  },
  // {
  //   name: "Email",
  //   selector: (tableData) => tableData?.Email || "NA",
  //   wrap: true,
  // },
  {
    name: "City",
    selector: (tableData) => tableData?.City || "NA",
    wrap: true,
  },
  {
    name: "ClaimDate",
    selector: (tableData) => tableData?.ClaimDate || "NA",
    wrap: true,
  },
  {
    name: "Date of Winning",
    selector: (tableData) => tableData?.Date_of_Winning || "NA",
    wrap: true,
  },
  {
    name: "GratificationType",
    selector: (tableData) => tableData?.GratificationType || "NA",
    wrap: true,
  },
  {
    name: "IsClaimed",
    selector: (tableData) => tableData?.IsClaimed || "NA",
    wrap: true,
  },
  {
    name: "MobileNo",
    selector: (tableData) => tableData?.MobileNo || "NA",
    wrap: true,
  },
  {
    name: "Pincode",
    selector: (tableData) => tableData?.Pincode || "NA",
    wrap: true,
  }, 
   {
    name: "State",
    selector: (tableData) => tableData?.State || "NA",
    wrap: true,
  },
]

export const yoyoTicketColumns = [
  {
    name: "Name",
    selector: (tableData) => tableData?.Name || "NA",
    wrap: true,
  },
  {
    name: "Address",
    selector: (tableData) => tableData?.Address || "NA",
    wrap: true,
  },
  // {
  //   name: "Email",
  //   selector: (tableData) => tableData?.Email || "NA",
  //   wrap: true,
  // },
  {
    name: "City",
    selector: (tableData) => tableData?.City || "NA",
    wrap: true,
  },
  {
    name: "Date_of_Winning",
    selector: (tableData) => tableData?.Date_of_Winning || "NA",
    wrap: true,
  },
  {
    name: "State",
    selector: (tableData) => tableData?.State || "NA",
    wrap: true,
  },
  {
    name: "City",
    selector: (tableData) => tableData?.City || "NA",
    wrap: true,
  },
  {
    name: "GratificationType",
    selector: (tableData) => tableData?.GratificationType || "NA",
    wrap: true,
  },
  {
    name: "MobileNo",
    selector: (tableData) => tableData?.MobileNo || "NA",
    wrap: true,
  },
  {
    name: "Pincode",
    selector: (tableData) => tableData?.Pincode || "NA",
    wrap: true,
  },

]

//valid state list
export const CODE_NOT_FOUND_REGION = ["DL","PB", "HR", "UP", "UK", "JK", "HP"];

// export const CODE_NOT_FOUND_REGION = []


