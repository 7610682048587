import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

import { claimFormValidationSchema } from "../../utils/validations";
import { ReactSelectInput, TextInput } from "../../utils/form-inputs";
import {
  getCityList,
  getStateList,
  handleKeyPressName,
  handleKeyPressPinCode,
  setFieldError,
} from "../../utils/helpers";
import useAuth from "../../utils/hooks/use-auth";
import { claimNowFitnessTracker } from "../../services/fitness-tracker-service";
import useLoader from "../../utils/hooks/use-loader";
import { fetchUserById } from "../../store/slices/auth-slice";
import { CLAIM_TYPE } from "../../utils/constants/common-constants";
import { claimNowYoYoTicket } from "../../services/yoyo-service";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../utils/helpers/floodlight-helper";

function ClaimForm({ onSuccess, type,showErrorModel }) {
  const dispatch = useDispatch();
  const { userDetails } = useAuth();
  const { toggleLoader } = useLoader();
  const stateList = getStateList();

  const stateOptions = useMemo(() => {
    return stateList.map((s) => ({ label: s.name, value: s.name }));
  }, [stateList]);

  const { handleSubmit, control, watch, setValue, setError } = useForm({
    resolver: yupResolver(claimFormValidationSchema),
    defaultValues: {
      Name: userDetails?.Name || "",
      MobileNo: userDetails?.MobileNo || "",
      Email: userDetails?.Email || "",
      AddressLine1: "",
      AddressLine2: "",
      State: "",
      City: "",
      PinCode: "",
      Latitude: 0,
      Longitude: 0,
    },
  });

  const selectedState = watch("State");

  useEffect(() => {
    // re-set city when state change
    setValue("City", "");
  }, [selectedState, setValue]);

  const cityOptions = useMemo(() => {
    if (!selectedState) return [];

    const stateDetail = stateList.find((s) => s.name === selectedState);
    if (!stateDetail) return [];

    const cities = getCityList(stateDetail.isoCode);
    return cities.map((c) => ({ label: c.name, value: c.name }));
  }, [selectedState, stateList]);

  const onSubmit = async (data) => {
    if (!type) return toast.error("Please specify claim type!");

    toggleLoader(true);

    const reqData = {
      ...data,
      MobileNo: userDetails?.MobileNo,
      // Id: userDetails.Id,
      UserId: userDetails.Id,
    };

    try {
      const res =
        type === CLAIM_TYPE.FIT_BIT
          ? await claimNowFitnessTracker(reqData)
          : await claimNowYoYoTicket(reqData);
      if (res.status === 200) {
        counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_DetailClaimNow_Counter);
        if(type == CLAIM_TYPE.YOYO_TICKET){
          window.dataLayer.push({event: 'lmsyoyotestclaimnowsubmit'});
        }
        else{
          window.dataLayer.push({event: 'lmsuniquecodeclaimsubmitform'});
        }  
        onSuccess();
        dispatch(fetchUserById());
      }
    } catch (err) {
      setFieldError({ err, fieldName: "", setError });
      let errorRes = err.response && err.response.data;
      console.log('errorRes',errorRes)
      showErrorModel(errorRes.Message)
    } finally {
      toggleLoader(false);
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form-group-vs-sm mt-10"
      >
        <div className="form-group">
          <TextInput
            control={control}
            name="Name"
            type="text"
            inputProps={{
              placeholder: "Name*",
            }}
            onInput={handleKeyPressName}
          />
        </div>
        <div className="form-group">
          <TextInput
            control={control}
            name="MobileNo"
            type="text"
            inputProps={{
              placeholder: "Mobile*",
              readOnly: true,
            }}
          />
        </div>
        <div className="form-group">
          <TextInput
            control={control}
            name="Email"
            type="email"
            inputProps={{
              placeholder: "Email*",
            }}
          />
        </div>
        <div className="form-group">
          <TextInput
            control={control}
            name="AddressLine1"
            type="text"
            inputProps={{
              placeholder: "Address Line 1*",
            }}
          />
        </div>
        <div className="form-group">
          <TextInput
            control={control}
            name="AddressLine2"
            type="text"
            inputProps={{
              placeholder: "Address Line 2",
            }}
          />
        </div>
        <div className="form-group">
          <ReactSelectInput
            control={control}
            name="State"
            options={stateOptions}
            inputProps={{
              placeholder: "State*",
            }}
          />
        </div>
        <div className="form-group">
          <ReactSelectInput
            control={control}
            name="City"
            options={cityOptions}
            inputProps={{
              placeholder: "City*",
            }}
          />
        </div>
        <div className="form-group">
          <TextInput
            control={control}
            name="PinCode"
            type="text"
            inputProps={{
              placeholder: "Pincode*",
              maxLength: 6,
            }}
            onInput={handleKeyPressPinCode}
          />
        </div>

        <button type="submit" className="btn btn-primary btn-block">
          Claim Now
        </button>
      </form>
    </div>
  );
}

export default ClaimForm;
