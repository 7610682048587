import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import AppModal from "../../components/modals";
import useAuth from "../../utils/hooks/use-auth";
import usePopup from "../../utils/hooks/use-popup";
import { handleShare } from "../../utils/helpers";
import { useSelector } from "react-redux";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../utils/helpers/floodlight-helper";

function ClaimYoYoSuccess({ show, onClose }) {
  const navigate = useNavigate();
  const { userDetails } = useAuth();
  const { openUniqueCodePopup } = usePopup();

  const timeRemain = useSelector((state) => state.common.timeRemain);

  const handleShareTicket = useCallback(() => {
    counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_Share_Counter);
    const dataToShare = {
      title: "I just won a ticket to the ICC World Cup Match! You can too!",
      text: "Just grab a bottle of Limca® Sportz and take the #YoYoTest!",
      image: "/assets/img/social-share/share-ticket.jpg",
      url: "Visit https://lmsicc.coke2home.com/",
    };
    handleShare(dataToShare);
  }, []);

  const renderActions = () => {
    if (userDetails?.IsFitbitClaim) return null;
    else if (
      userDetails?.IsFitnessTrackerWon &&
      !userDetails?.IsFitbitClaimTimeOver
    ) {
      return null;
    } else if (timeRemain) return null;
    return (
      <>
        <p className="my-3">Here's your chance to win a Fitness Tracker</p>
        <Button
          className="btn btn-primary btn-block"
          onClick={() => {
            navigate("/");
            onClose();
            openUniqueCodePopup();
          }}
        >
          Enter Unique Code
        </Button>
      </>
    );
  };

  return (
    <div>
      <AppModal
        show={show}
        onClose={onClose}
        heading="Congratulations Champ!"
        headingClass="text-primary"
      >
        <p className="mt-3">
          Cheers to your #RukkMatt attitude! Your tickets will be <br />
          delivered within 28 business days.
        </p>
        <img
          className="w-50"
          src="/assets/img/products/share-ticket.png"
          alt="Share Ticket"
        />
        <h6 className="mt-0 mb-3">
          Ab RukkMatt, tell your gang about the win!
        </h6>
        <div className="social-link">
          <a href="/assets/img/social-share/Ticket.png" download>
            <img
              className="icon"
              src="/assets/img/icons/download.svg"
              alt="Ticket"
            />
          </a>
          <Link>
            <img
              className="icon"
              src="/assets/img/icons/Share.svg"
              alt="Ticket"
              onClick={handleShareTicket}
            />
          </Link>
        </div>

        {renderActions()}
      </AppModal>
    </div>
  );
}

export default ClaimYoYoSuccess;
