import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { otpValidationSchema } from "../../utils/validations";
import { TextInput } from "../../utils/form-inputs";
import useLoader from "../../utils/hooks/use-loader";
import { sendOtp, verifyOtp } from "../../services/auth-service";
import { handleKeyPressotp, setFieldError } from "../../utils/helpers";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../utils/helpers/floodlight-helper";
import { MetaPixel } from "../../utils/helpers/metaHelper";

function VerifyOTP({ MobileNo, onSuccess }) {
  const { toggleLoader } = useLoader();

  const [resendSuccess, setResendSuccess] = useState(false);

  useEffect(() => {
    if (resendSuccess) {
      setTimeout(() => {
        setResendSuccess(false);
      }, 5000);
    }
  }, [resendSuccess]);

  const { handleSubmit, control, setError, clearErrors } = useForm({
    resolver: yupResolver(otpValidationSchema),
    defaultValues: {
      OTP: "",
    },
  });

  const onSubmit = async (values) => {
    counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_OTPVerify_Counter);
    // counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_Login_Counter);
    toggleLoader(true);
    try {
      const reqData = {
        MobileNo,
        ...values,
      };
      const res = await verifyOtp(reqData);
      if (res.status === 200) {
        window.dataLayer.push({ event: "lmsenterotplogin" });
        const data = res?.data?.Data;
        onSuccess(data);
      }
    } catch (err) {
      setFieldError({ err, fieldName: "OTP", setError });
    } finally {
      toggleLoader(false);
    }
  };

  const resendOtp = async () => {
    toggleLoader(true);
    try {
      const reqData = {
        MobileNo,
      };
      const res = await sendOtp(reqData);
      if (res.status === 200) {
        MetaPixel('SubmitOTPLogin');
        window.dataLayer.push({ event: "lmsresendotp" });
        setResendSuccess(true);
        clearErrors("OTP");
      }
    } catch (err) {
      setFieldError({ err, fieldName: "OTP", setError });
    } finally {
      toggleLoader(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <TextInput
            control={control}
            name="OTP"
            type="text"
            inputProps={{
              placeholder: "Enter OTP",
              autoComplete: "off",
              maxLength: 6,
            }}
            customMessage={
              resendSuccess ? (
                <p className="invalid-feedback d-block text-success">
                  A new OTP has been sent
                </p>
              ) : null
            }
            onInput={handleKeyPressotp}
          />
        </div>
        <button type="submit" className="btn btn-primary btn-block">
          Login
        </button>
        <Link className="modal-link mt-4" onClick={resendOtp}>
          Resend OTP
        </Link>
      </form>
    </div>
  );
}

export default VerifyOTP;
