import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidationSchema } from "../../utils/validations";
import { TextInput } from "../../utils/form-inputs";
import { sendOtp } from "../../services/auth-service";
import useLoader from "../../utils/hooks/use-loader";
import { handleKeyPressMobileNumber, setFieldError } from "../../utils/helpers";
import { TERMS_AND_CONDITIONS_URL } from "../../utils/constants";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../utils/helpers/floodlight-helper";
import { MetaPixel } from "../../utils/helpers/metaHelper";

function Login({ setSendOtpSuccess, setMobileNo }) {
  const { toggleLoader } = useLoader();

  const { handleSubmit, control, setError } = useForm({
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      MobileNo: "",
    },
  });

  const onSubmit = async (values) => {
    toggleLoader(true);
    try {
      const reqData = {
        ...values,
      };
      const res = await sendOtp(reqData);
      if (res.status === 200) {
        window.dataLayer.push({ event: "lmsmobilenosubmit" });
        counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_GetOTP_Counter);
        MetaPixel('SubmitMobileNumber');
        setMobileNo(values.MobileNo);
        setSendOtpSuccess(true);
      }
    } catch (err) {
      setFieldError({ err, fieldName: "MobileNo", setError });
    } finally {
      toggleLoader(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <TextInput
            control={control}
            name="MobileNo"
            type="tel"
            inputProps={{
              placeholder: "Enter Mobile Number",
            }}
            onInput={handleKeyPressMobileNumber}
          />
        </div>

        <button type="submit" className="btn btn-primary btn-block">
          Submit
        </button>
        <Link
          to={TERMS_AND_CONDITIONS_URL}
          target={"_blank"}
          className="modal-link mt-4"
          onClick={() => {
            counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_TC_Counter);
          }}
        >
          T&C
        </Link>
      </form>
    </div>
  );
}

export default Login;
