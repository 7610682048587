import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getUserClaimTimeById } from "../../services/fitness-tracker-service";
import { calculateTimeRemainingToClaim } from "../../utils/helpers";

/**
 * @member fetchIPDetails
 * @description fetch and set ip details in redux store
 */
export const fetchIPDetails = createAsyncThunk(
  "common/fetchIPDetails",
  async () => {
    const ipRes = await (
      await fetch("https://api.ipify.org?format=json")
    ).json();
    const ipData = await (
      await fetch(
        `https://pro.ip-api.com/json/${ipRes?.ip}?key=pXmgwzTKeZ7sORl`
      )
    ).json();

    return ipData;
  }
);

export const getUserClaimTime = createAsyncThunk(
  "auth/getUserClaimTime",
  async (userId, { getState, rejectWithValue }) => {
    const Id = userId || getState()?.auth?.userDetails?.Id;
    if (!Id) return rejectWithValue("Id required");
    const reqData = {
      Id: Id,
    };
    const res = await getUserClaimTimeById(reqData);
    return res.data.Data;
  }
);

// Define the initial state for the loading slice
const initialState = {
  isLoading: false, // Indicates if the application is currently in a loading state
  ipDetails: null, // Stores the IP details
  currentLanguage: "en", // current selected language
  recording: null,
  claimTime: null, // fibit won time, to calculate remaining time, 48Hr from this
  timeRemain: false,
  cdpCache: {},
  level:{},
  adminDetails:{
    adminReportRoute:"GetCultOnGroundDataList"
  },
  promoterDetails:{},
  setSmsLinkRedirection:{popup: '', loginJourney: false}
};

// Create the loading slice using `createSlice`
const commonSlice = createSlice({
  name: "common", // Name of the slice
  initialState, // Initial state defined above
  reducers: {
    // Reducer function for toggling the loading state
    toggleLoadingRed: (state, actions) => {
      state.isLoading = actions?.payload ? actions.payload : !state.isLoading; // Toggle the loading state based on the payload of the action or invert the current loading state
    },
    // Reducer function for setting IP details
    setIpDetails: (state, actions) => {
      state.ipDetails = actions.payload; // Set the IP details to the payload of the action
    },
    setLanguage: (state, actions) => {
      state.currentLanguage = actions.payload; // Set the IP details to the payload of the action
    },
    setRecording: (state, actions) => {
      state.recording = actions.payload; // Set the IP details to the payload of the action
    },
    setCDP: (state, actions) => {
      state.cdpCache = { ...state.cdpCache, ...actions.payload }; // act as cdp cache to send event only once
    },
    setLevelForFilter: (state, actions) => {
      state.level = { ...state.level, ...actions.payload }; // act as cdp cache to send event only once
    },
    setAdminDetails: (state, actions) => {
      state.adminDetails = { ...state.adminDetails, ...actions.payload }; // act as cdp cache to send event only once
    },
    setPromoterDetails: (state, actions) => {
      state.promoterDetails = { ...state.promoterDetails, ...actions.payload }; // act as cdp cache to send event only once
    },
    setSmsLinkRedirection: (state, actions) => {
      state.smsLinkRedirection = { ...state.smsLinkRedirection, ...actions.payload }; // act as cdp cache to send event only once
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIPDetails.fulfilled, (state, action) => {
      state.ipDetails = action.payload;
    });
    builder
      .addCase(getUserClaimTime.fulfilled, (state, action) => {
        const time = `${action.payload.Date}T${action.payload.Time}Z`;
        state.claimTime = time;
        const { time: claimTimeRemain } = calculateTimeRemainingToClaim(time);
        state.timeRemain = claimTimeRemain ? true : false;
      })
      .addCase(getUserClaimTime.rejected, (state, action) => {
        state.claimTime = null;
        state.timeRemain = false;
      });
  },
});

// Extract the action creators generated by `createSlice`
export const {
  toggleLoadingRed,
  setIpDetails,
  setLanguage,
  setRecording,
  setCDP,
  setLevelForFilter,
  setAdminDetails,
  setSmsLinkRedirection,
  setPromoterDetails
} = commonSlice.actions;

// Export the reducer function generated by `createSlice`
export default commonSlice.reducer;
