import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import HomePage from '../../pages/home-page';
import App from '../../App';
import Popups from '../../pages/popups';
import Page404 from '../../pages/404';
import MyWinnings from '../../pages/my-winnings';
import Yoyo from '../../pages/yoyo';
import Winner from '../../pages/winner';
import Activity from '../../pages/activity';
import OnGroundPage from '../../pages/on-ground';
import CollegeLeg from '../../pages/college-leg';
import LeaderBoardPage from '../../pages/leader-board-page';
import CultFitOnGround from '../../pages/cultfit-onground';
import Admin from '../../pages/admin';
import AdminLeaderBoard from '../../pages/admin-leaderBoard';
import Promoter from '../../pages/promoter';


export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<Page404 />}>
      <Route path="/" element={<HomePage />} />
      <Route path="/my-winnings" element={<MyWinnings />} />
      <Route path="/yoyo" element={<Yoyo />} />
      <Route path="/winner" element={<Winner />} />
      <Route path="/activity" element={<Activity />} />
      <Route path="/on-ground" element={<OnGroundPage />} />
      <Route path="/leader-board" element={<LeaderBoardPage />} />
      <Route path="/on-college" element={<CollegeLeg />} />
      <Route path="/cultfit-onground" element={<CultFitOnGround />} />
      <Route path="/admin" element={<Admin/>}/>
      <Route path="/admin-leaderBoard" element={<AdminLeaderBoard/>}/>
      <Route path="/promoter" element={<Promoter/>}/>
      {process.env.REACT_APP_ENV !== 'production' && (
        <>
          <Route path="/popups" element={<Popups />} />
        </>
      )}
    </Route>
  )
);
