import React, { useState } from 'react';
import AdminLogin from '../components/admin/admin-login-popup';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import OnGroundAdminForm from '../components/admin/on-ground-admin';

const Promoter = () =>{
    const [loggedIn, setLoggedIn] = useState(false);
    const isAdminLogin = useSelector((state) => (state?.common?.promoterDetails))
    const navigate = useNavigate()

    const handleLoginSuccess = () => {
        navigate("/promoter")
    };

    const handleClose = () => {
        setLoggedIn(false)
    }
    return(
        <>
                {isAdminLogin?.promoterDetails ? (
                    <OnGroundAdminForm route="on-college"/>
                ) : (
                    <AdminLogin setLoggedIn={setLoggedIn} handleClose={handleClose} onLoginSuccess={handleLoginSuccess} route={"promoter"}/>
                )}
        </>
    )
}

export default Promoter;