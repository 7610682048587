import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import useLoader from "../../utils/hooks/use-loader";
import { setFieldError } from "../../utils/helpers";
import { TextInput } from "../../utils/form-inputs";
import { useDispatch } from "react-redux";
import { fetchUserById } from "../../store/slices/auth-slice";

function FieldForm({
  Id,
  initialValue = {},
  editing = false,
  setEdit,
  fieldName = "",
  API = "updateUserName",
  validationSchema = "",
  canNotEdit = false,
  type = "text",
  onInput,
  label
}) {
  const dispatch = useDispatch();
  const { toggleLoader } = useLoader();

  const { handleSubmit, control, setError } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValue,
  });

  const onSubmit = async (values) => {
    if (canNotEdit) return null;
    toggleLoader(true);
    try {
      const reqData = {
        ...values,
        Id,
      };
      const res = await API(reqData);
      if (res.status === 200) {
        // const data = res?.data?.Data;
        dispatch(fetchUserById());
        setEdit((p) => p.filter((d) => d !== fieldName));
      }
    } catch (err) {
      setFieldError({ err, fieldName, setError });
    } finally {
      toggleLoader(false);
    }
  };
  console.log(label, "placeholder")
  const renderEditForm = () => {
    if (canNotEdit) return null;
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row align-items-center">
            <div className="col">
              <div className="form-group mb-1">
                <TextInput
                  control={control}
                  name={fieldName}
                  inputProps={{
                    placeholder: fieldName,
                    autoComplete: "off",
                    style: {
                      boxShadow: "none",
                    },
                  }}
                  type={type}
                  onInput={onInput}
                />
              </div>
            </div>
            <div className="col-4">
              <button type="submit" className="btn-submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };

  const renderValue = () => {
    return (
      <div className="row no-gutters justify-content-between">
        <div className="col text-truncate">{initialValue[fieldName]}</div>
        <div className="col-3 text-right">
          {!canNotEdit && (
            <button
              type="button"
              className="btn-edit"
              onClick={() => setEdit((p) => [...p, fieldName])}
            >
              <img src="/assets/img/icons/edit-icon.png" alt="edit icon" />
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="row align-items-center">
      <div className="col-3">
        {fieldName === "MobileNo" ? "Phone" : (label ? label : fieldName)}
      </div>

      <div className="col-9 pr-1">
        {editing && !canNotEdit ? renderEditForm() : renderValue()}
      </div>
    </div>
  );
}
 
export default FieldForm;
