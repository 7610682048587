import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import ProfileForm from '../../features/profile';
import useAuth from '../../utils/hooks/use-auth';
import { useDispatch, useSelector } from 'react-redux';
import usePopup from '../../utils/hooks/use-popup';
import { setSmsLinkRedirection } from '../../store/slices/common-slice';

function ProfileMenu() {
  const { userDetails,isLoggedIn } = useAuth();
  const [open, setOpen] = useState(false);
  const smsLinkRedirection = useSelector((state) => state?.common?.smsLinkRedirection)
  const { openLoginPopup } = usePopup();
  const dispatch = useDispatch()
const checkSmsLinkRedirection = smsLinkRedirection && smsLinkRedirection?.popup
  const handleToggleProfileMenu = (isOpen, event, metadata) => {
    setOpen((p) => isOpen);
    if(isOpen === false){
      dispatch(setSmsLinkRedirection({ popup: '', loginJourney: false }))
    }
  };

  useEffect(() => {
    if (smsLinkRedirection && smsLinkRedirection.popup) {
      if(!isLoggedIn){
        openLoginPopup()
      }else if (smsLinkRedirection.popup === 'LMS_Profile') {
        setOpen(true);
      }
    }
  }, [checkSmsLinkRedirection])// eslint-disable-line react-hooks/exhaustive-deps

  

  return (
    <>
      <Dropdown
        show={open}
        key={'down-centered'}
        drop={'down-centered'}
        onToggle={handleToggleProfileMenu}
        className="profile">
        <Dropdown.Toggle
          id="profile-drop-down"
          as="img"
          className="thumbnail-image"
          src={
            userDetails?.ProfileImg || '/assets/img/avatars/default-user.svg'
          }
          alt="user pic"
          style={{ height: '37px', width: '37px' }}
        />
        <Dropdown.Menu show={open} className="profile-modal">
          <ProfileForm open={open} />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default ProfileMenu;
