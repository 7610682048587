import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import FitnessTrackerWinning from "../features/my-winnings/fitness-tracker-winning";
import YoYoWinning from "../features/my-winnings/yo-yo-winning";
import useAuth from "../utils/hooks/use-auth";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../utils/helpers/floodlight-helper";

function MyWinnings() {
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_MyWinning_Counter);
  }, []);

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="container-fluid mt-10">
      <Row className="mt-4 mt-sm-8 justify-content-center">
        <Col xs={12}>
          <Row className="justify-content-center">
            <Col xs={12} sm={10} md={6} className="mb-4 mb-md-0">
              <FitnessTrackerWinning />
            </Col>
            <Col xs={12} sm={10} md={6}>
              <YoYoWinning />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default MyWinnings;
