import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

const AVATAR_LIST = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

function ProfileImageSelector({
  selected = "",
  onChange,
  error,
  title = "Choose your avatar",
  dropPosition = "",
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (selected) {
      setOpen(false);
    }
  }, [selected]);

  return (
    <>
      <Dropdown show={open} onToggle={setOpen} drop={dropPosition}>
        <Dropdown.Toggle as="div" className="avatar-wrapper">
          <img
            id="profile-image-drop-down"
            as="img"
            className="avatar-img mb-1"
            src={selected || "/assets/img/avatars/default-user.svg"}
            alt="add-icon"
          />
          <img
            className="avatar-edit"
            src="/assets/img/icons/plus-icon.svg"
            alt="add-icon"
          />
        </Dropdown.Toggle>
        {/* <Dropdown.Toggle
          id="profile-image-drop-down"
          as="img"
          className="avatar-img"
          src={selected || '/assets/img/avatars/default-user.svg'}
          alt="add-icon"
        />
        <img
          className="avatar-edit"
          src="/assets/img/icons/plus-icon.svg"
          alt="add-icon"
        /> */}
        {/* <Dropdown.Toggle
          as="img"
          className="avatar-edit"
          src="/assets/img/icons/plus-icon.svg"
          alt="add-icon"
        /> */}
        <Dropdown.Menu className="p-0" style={{ borderRadius: "1rem" }}>
          <div className={`avatar-list ${dropPosition}`}>
            {AVATAR_LIST.map((a) => (
              <img
                className="avatar-img"
                src={`/assets/img/avatars/${a}.svg`}
                alt="avatar"
                onClick={() => onChange(`/assets/img/avatars/${a}.svg`)}
                key={a}
              />
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {error && (
        <p className={`invalid-feedback ${error ? "d-block" : ""}`}>{error}</p>
      )}
      <p className="avatar-title">{title}</p>
    </>
  );
}

export default ProfileImageSelector;
