import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";

export const getWinnerList = async (data) => {
  console.log(data, "data");
  return await AxiosInstance.get(
    `${API_END_POINT.getAllWinner}?PageNumber=${data?.PageNo || 1}`
  );
};

export const CultfitLeaderBoardData = async (data) => {
  return await AxiosInstance.post(
    API_END_POINT.GetCultfitLeaderBoarddata,
    data
  );
};

export const GetLeaderBoarddata = async (data) => {
  return await AxiosInstance.post(
    API_END_POINT.GetLeaderBoarddata,
    data
  );
};

export const GetCultfitVenue = async (data) => {
  return await AxiosInstance.post(API_END_POINT.GetCultfitVenue,data);
};


export const GetOngroundVenue = async () =>{
  return await AxiosInstance.post(API_END_POINT.GetOngroundVenue);
}

