import React from 'react';
import { Modal } from 'react-bootstrap';

function AppModal({
  children,
  show,
  onClose,
  heading = '',
  subHeading = '',
  headerClass = '',
  headingClass = '',
  bodyClass = '',
  centered = true,
  hideClose = false,
  ...rest
}) {
  return (
    <Modal show={show} onHide={onClose} centered={centered} {...rest}>
      <Modal.Header className={headerClass}>
        {!hideClose && (
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onClose}></button>
        )}
        {heading && (
          <Modal.Title
            as="h3"
            className={headingClass}
            dangerouslySetInnerHTML={{ __html: heading }}></Modal.Title>
        )}
        {subHeading && (
          <h6 dangerouslySetInnerHTML={{ __html: subHeading }}></h6>
        )}
      </Modal.Header>

      <Modal.Body className={bodyClass}>{children}</Modal.Body>
    </Modal>
  );
}

export default AppModal;
