import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import Auth from '../features/auth';
import UniqueCode from '../features/unique-code';
import Claim from '../features/claim';
import UniqueCodeSuccess from '../features/unique-code/unique-code-success';
import UniqueCodeClaimTimeOut from '../features/unique-code/unique-code-claim-time-out';
import ClaimSuccess from '../features/claim/claim-success';
import FitnessTrackerSocialShare from '../features/claim/fitness-tracker-social-share';
import UniqueCodeFail from '../features/unique-code/unique-code-fail';
import HowToPlay from '../features/unique-code/how-to-play';
import YoYoTestRecording from '../features/yoyo-test/recording/yoyo-test-recording';
import YoYoTestInstructions from '../features/yoyo-test/yoyo-test-instructions';
import YoYoEntrySuccess from '../features/yoyo-test/yoyo-entry-success';

import FitBitWon from '../features/unique-code/fitbit-won';
import ClaimYoYoSuccess from '../features/claim/claim-yoyo-success';
import GenerateCertificate from '../features/yoyo-test/generate-certificate';

import GenerateCertificate2 from '../features/yoyo-test/generate-certificate2';

function Popups() {
  const [open, setOpen] = useState('');

  const handleOpen = (modelToOpen) => () => {
    setOpen(modelToOpen);
  };
  const handleClose = () => {
    setOpen('');
  };

  const shouldOpen = (modelName) => {
    return open && open === modelName ? true : false;
  };

  return (
    <div className="container-fluid py-5">
      <h1 className="text-center">Popups</h1>
      <div className="row row-cols-3">
        <div className="col mb-3">
          <Button onClick={handleOpen('login')}>Login</Button>
          <Auth show={shouldOpen('login')} onClose={handleClose} />
        </div>
        <div className="col mb-3">
          <Button onClick={handleOpen('unique-code')}>Unique Code</Button>
          <UniqueCode show={shouldOpen('unique-code')} onClose={handleClose} />
        </div>

        <div className="col mb-3">
          <Button onClick={handleOpen('unique-code-success')}>
            Unique Code Success
          </Button>
          <UniqueCodeSuccess
            show={shouldOpen('unique-code-success')}
            onClose={handleClose}
          />
        </div>
        {/* <div className="col mb-3">
          <Button onClick={handleOpen("unique-code-claim-time-out")}>
            Unique Code Claim Time Out
          </Button>
          <UniqueCodeClaimTimeOut
            show={shouldOpen("unique-code-claim-time-out")}
            onClose={handleClose}
          />
        </div> */}
        <div className="col mb-3">
          <Button onClick={handleOpen('unique-code-fail')}>
            Unique code fail
          </Button>
          <UniqueCodeFail
            show={shouldOpen('unique-code-fail')}
            onClose={handleClose}
          />
        </div>
        <div className="col mb-3">
          <HowToPlay show={shouldOpen('how-to-play')} onClose={handleClose} />
        </div>
        <div className="col mb-3">
          <Button onClick={handleOpen('claim')}>Claim</Button>
          <Claim show={shouldOpen('claim')} onClose={handleClose} />
        </div>
        <div className="col mb-3">
          <Button onClick={handleOpen('claim-success')}>Claim Success</Button>
          <ClaimSuccess
            show={shouldOpen('claim-success')}
            onClose={handleClose}
          />
        </div>
        <div className="col mb-3">
          <Button onClick={handleOpen('fitness-tacker-social-share')}>
            Fitness Tracker Social Share
          </Button>
          <FitnessTrackerSocialShare
            show={shouldOpen('fitness-tacker-social-share')}
            onClose={handleClose}
          />
        </div>
        <div className="col mb-3">
          <Button onClick={handleOpen('fitbit-won')}>FitBit Won</Button>
          <FitBitWon show={shouldOpen('fitbit-won')} onClose={handleClose} />
        </div>
        <div className="col mb-3">
          <YoYoTestInstructions />
        </div>
        <div className="col mb-3">
          <Button onClick={handleOpen('yoyo-recording')}>YoYo Recording</Button>
          <YoYoTestRecording
            show={shouldOpen('yoyo-recording')}
            onClose={handleClose}
          />
        </div>
        <div className="col mb-3">
          <Button onClick={handleOpen('yoyo-won')}>YoYo Won</Button>
          <ClaimYoYoSuccess
            show={shouldOpen('yoyo-won')}
            onClose={handleClose}
          />
        </div>
        <div className="col mb-3">
          <Button onClick={handleOpen('yoyo-entry-success')}>
            YoYo Entry Success
          </Button>
          <YoYoEntrySuccess
            show={shouldOpen('yoyo-entry-success')}
            onClose={handleClose}
          />
        </div>
        <div className="col mb-3">
          <Button onClick={handleOpen('ar-cert')}>Generate Certificate</Button>
        </div>
        <GenerateCertificate
          show={shouldOpen('ar-cert')}
          onClose={handleClose}
        />
        <div className="col mb-3">
          <Button onClick={handleOpen('ar-cert2')}>
            Generate Certificate2
          </Button>
        </div>
        <GenerateCertificate2
          show={shouldOpen('ar-cert2')}
          onClose={handleClose}
        />
      </div>
    </div>
  );
}

export default Popups;
