import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auto } from '@popperjs/core';
import { Row, Col, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';

import HowToPlay from '../features/unique-code/how-to-play';
import useAuth from '../utils/hooks/use-auth';
import usePopup from '../utils/hooks/use-popup';
import AppModal from '../components/modals';
import { HomePageCDP, clickButtonCDP } from '../utils/helpers/cds-helper';
import { useSelector } from 'react-redux';
import {
  FLOODLIGHT_COUNTER,
  counterFloodLight,
} from '../utils/helpers/floodlight-helper';
import {
  RESTRICTED_STATE,
  ELIGIBLE_COUNTRY,
  CLAIM_TYPE
} from '../utils/constants/common-constants';
import { MetaPixel } from '../utils/helpers/metaHelper';

function HomePage() {
  const navigate = useNavigate();
  const { openClaimPopup,openLoginPopup, openUniqueCodePopup, openRestrictRegionPopup, openGenerateARCertificatePopup } =
    usePopup();
  const { isLoggedIn } = useAuth();
  const [showPlayer, setShowPlayer] = useState(false);
  const ipDetails = useSelector((state) => state.common.ipDetails);
  const restrictToRedirect =
    ipDetails && ipDetails.regionName === RESTRICTED_STATE ? true : false;
  const isCountrynotIndia = ipDetails && ipDetails.country !== ELIGIBLE_COUNTRY;
  console.log(restrictToRedirect, 'restrictToRedirect', isCountrynotIndia);
  const smsLinkRedirection = useSelector((state) => state?.common?.smsLinkRedirection)
  let loginJourney = smsLinkRedirection && smsLinkRedirection.loginJourney
  let popup = smsLinkRedirection && smsLinkRedirection.popup

  console.log(smsLinkRedirection, "smsLinkRedirection")
  useEffect(() => {
    if (restrictToRedirect || isCountrynotIndia) {
      openRestrictRegionPopup();
    } else {
      counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_HomePage_Counter);
    }
  }, []);

  useEffect(() => {
    if (ipDetails) {
      HomePageCDP();
    }
  }, [ipDetails]);

  useEffect(() => {
    if (smsLinkRedirection && smsLinkRedirection.popup) {
      if(isLoggedIn){
        if (smsLinkRedirection.popup === 'LMS_AddUniqueCode') {
          openUniqueCodePopup(true);
        }
      }else {
        openLoginPopup();
      }
    } 
    // else if(!isLoggedIn){
    //   openLoginPopup();
    // }
  }, [popup,loginJourney])// eslint-disable-line react-hooks/exhaustive-deps

  const handleWatchDemoClick = () => {
    clickButtonCDP('HowToWin_ICCTickets');
    MetaPixel('WatchDemo');
    setShowPlayer(true);
  };

  const handleParticipate = () => {
    if (restrictToRedirect || isCountrynotIndia) {
      openRestrictRegionPopup();
    } else if (!isLoggedIn) {
      openLoginPopup(true, 'openUniqueCodePopup');
    } else {
      // open unique code popup
      openUniqueCodePopup(true);
      MetaPixel('ParticipateNow');
      window.dataLayer.push({ event: 'lmsfitnessparticipatenow' });
      counterFloodLight(
        FLOODLIGHT_COUNTER.LimcaSportz_ICC23_ParticipateNow_Counter
      );
    }
  };

  const handleTakeYoYOTest = () => {
    if (restrictToRedirect || isCountrynotIndia) {
      openRestrictRegionPopup();
    } else {
      if (!isLoggedIn) {
        openLoginPopup(true, '/yoyo');
      } else {
        counterFloodLight(
          FLOODLIGHT_COUNTER.LimcaSportz_ICC23_TakeTheTest_Counter
        );
        MetaPixel('TaketheTest');
        window.dataLayer.push({ event: 'lmstakeyoyotest' });
        navigate('/yoyo');
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        <Row className="justify-content-center">
          <Col xs={12} md={10}>
            <Row className="row no-gutters text-center justify-content-center pt-8 pt-sm-12 pb-6">
              <Col xs={3} sm={4} md={auto} className="mt-sm-n2 mr-sm-n5">
                <img
                  className="participate-fitbit"
                  src="/assets/img/products/ft.svg"
                  alt="Fitness Track"
                />
              </Col>
              <Col xs={9} sm={6}>
                <h1 className="mb-2 mb-sm-4">
                  <span className="text-nowrap d-block">
                    ONE FITNESS TRACKER
                  </span>{' '}
                  EVERY HOUR*
                </h1>
                <h4 className="text-primary text-capitalize mb-2 mb-sm-4">
                  Just grab a Limca® Sportz bottle{' '}
                  <br className="d-block d-sm-none" />
                  and <br className="d-none d-sm-block" />
                  get a chance to win*!
                </h4>
                <Button onClick={handleParticipate}>PARTICIPATE NOW</Button>
                <p className="mt-2 mt-sm-4 mb-0">
                  <HowToPlay />
                </p>
              </Col>
            </Row>

            <div className="border-dash"></div>

            <Row className="row text-center justify-content-center pt-6">
              <Col xs={12}>
                <p className="text-capitalize">Up For Grabs</p>
                <h1 className="mb-1 mb-sm-4">
                  ICC Men's Cricket World CUP <br />
                  2023 Match Tickets*
                </h1>
                <h4 className="text-primary">
                  Take the #YoYoTest for #LimcaSportz{' '}
                  <br className="d-block d-sm-none" />
                  and <br className="d-none d-sm-block" />
                  stand a chance to win*
                </h4>

                <Row className="justify-content-end align-items-center mx-0 bg-blur2">
                  <Col
                    xs={5}
                    sm={4}
                    lg={3}
                    className="yoyo-block d-none d-sm-flex justify-content-center">
                    <img
                      className="yoyo-logo"
                      src="/assets/img/common/logo4.png"
                      alt="logo"
                    />
                    <Link
                      className="btn btn-primary mx-xl-3 my-2 my-sm-4 my-md-6"
                      rel="noreferrer"
                      onClick={handleWatchDemoClick}>
                      Watch Demo
                    </Link>

                    {showPlayer && (
                      <AppModal
                        show={showPlayer}
                        contentClassName="p-0 bg-transparent"
                        bodyClass="max-w-none p-0"
                        onClose={() => setShowPlayer(false)}>
                        <ReactPlayer
                          url="https://www.youtube.com/watch?v=ZA_aohvK5oM"
                          controls
                          width="100%"
                          height="360px"
                        />
                      </AppModal>
                    )}
                    <Link
                      className="btn btn-primary mx-xl-3 my-2 my-sm-4 my-md-6"
                      onClick={handleTakeYoYOTest}>
                      Take the test
                    </Link>
                  </Col>
                  <Col xs={12} sm={8}>
                    <img
                      className="yoyo-logo d-block d-sm-none"
                      src="/assets/img/common/logo3.png"
                      alt="logo"
                    />
                    <Row className="justify-content-start mt-14 pt-4">
                      <Col xs={9} sm={12} className="pr-sm-0">
                        <img
                          className="d-none d-sm-block"
                          src="/assets/img/common/neeraj.webp"
                          alt="Neeraj"
                        />
                        <img
                          className="d-block d-sm-none"
                          src="/assets/img/common/neeraj2.webp"
                          alt="Neeraj"
                        />
                      </Col>
                      <Col xs={12} className="d-block d-sm-none">
                        <Row className="justify-content-center mb-6">
                          <Col xs={6}>
                            <Link
                              className="btn btn-primary btn-block"
                              rel="noreferrer"
                              onClick={handleWatchDemoClick}>
                              Watch Demo
                            </Link>
                          </Col>
                          <Col xs={6}>
                            <Link
                              className="btn btn-primary btn-block"
                              onClick={handleTakeYoYOTest}>
                              Take the test
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default HomePage;
