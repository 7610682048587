import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import {
  ReactSelectInput,
  SeparateInput,
  TextInput,
} from '../../utils/form-inputs';
import { yoyoOnGroundTestFormValidationSchema } from '../../utils/validations';
import useAuth from '../../utils/hooks/use-auth';
import useLoader from '../../utils/hooks/use-loader';
import ReCaptcha from '../../components/common/recaptcha';
import { SHUTTLES_COUNT } from '../../utils/constants/common-constants';
import {
  registerOnCultfitOnGround,
  registerOnCollegeYoYoTest,
} from '../../services/yoyo-service';
import {
  handleKeyPressMobileNumber,
  handleKeyPressName,
} from '../../utils/helpers';
import AppModal from '../../components/modals';
import { useNavigate } from 'react-router';
import {
  GetCultfitVenue,
  GetOngroundVenue,
} from '../../services/winner-service';
import { useDispatch, useSelector } from 'react-redux';
import { setAdminDetails, setLevelForFilter, setPromoterDetails } from '../../store/slices/common-slice';
import { updateUserName } from '../../services/user-service';
import { RegisterAdminOnGroundYoYoTest } from '../../services/admin-service';

function TestForm({ route }) {
  const { userDetails } = useAuth();
  const { toggleLoader } = useLoader();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [adminFormSuccess, setAdminFormSuccess] = useState(false)
  const [cityListing, setCityListing] = useState([]);
  const [cultVenuList, setCultVenuList] = useState([]);
  const reCaptchaRef = useRef();
  const navigate = useNavigate();
  const ipDetails = useSelector((state) => state.common.ipDetails);
  console.log(ipDetails, "ipDetails")
  const isCultFit = route === 'cultfit-onground' ? true : false;
  const isAdmin = route === 'on-ground-admin'
  console.log(isAdmin, "isAdmin")
  console.log(userDetails?.Name, 'name');
  const currentDate = dayjs().format('DD-MM-YYYY');
  const currentTime = dayjs().format('HH:mm')
  console.log(currentDate, 'currentDate');
  console.log(currentTime, "currentTime")

  const { handleSubmit, control, watch, setValue, trigger, reset } = useForm({
    resolver: yupResolver(yoyoOnGroundTestFormValidationSchema),
    defaultValues: {
      Name: isAdmin ? "" : userDetails?.Name,
      MobileNo: !isAdmin ? userDetails?.MobileNo : '',
      Venue: '',
      CreateDate: !isAdmin ? currentDate : '',
      State: '',
      City: '',
      Minutes: '',
      Seconds: '',
      Level: 'Beginner',
      Shuttles: isCultFit ? '04' : '02',
    },
    mode: 'all',
  });

  const selectedLevel = watch('Level');
  const minutesEntered = watch('Minutes');
  const selectedState = watch('State');
  const selectedCity = watch('City');
  const selectedVenue = watch('Venue');
  console.log(selectedVenue, 'selectedVenue');

  const dispatch = useDispatch();

  useMemo(() => {
    if (isAdmin) {
      setValue('Name', "");
    } else {
      return setValue('Name', userDetails?.Name);
    }
  }, [userDetails?.Name]);

  const cityOptions = useMemo(() => {
    console.log(selectedState, 'selectedState');
    return cityListing?.map((c) => ({
      label: c.CityName,
      value: c.CityName,
      ...c,
    }));
  }, [cityListing]);

  //cityDetails
  const cityDetails = useMemo(() => {
    if (!selectedCity) return [];
    return cityOptions.find((s) => s.CityName === selectedCity);
  }, [selectedCity]);

  useEffect(() => {
    fetchVenuList();
  }, []);

  useMemo(() => {
    const venue = cityDetails ? cityDetails?.VenueList : [];
    const venuList = venue?.map((v) => ({
      label: v.VenueName,
      value: v.VenueName,
      Id: v.Id,
    }));
    setCultVenuList(venuList);
  }, [cityDetails]);

  useEffect(() => {
    // re-set city when state change
    setValue('City', '');
  }, [selectedState, setValue]);

  useEffect(() => {
    setValue('Venue', '');
  }, [selectedCity]);

  useEffect(() => {
    if (minutesEntered) {
      trigger('Seconds');
    }
  }, [minutesEntered, trigger]);

  useEffect(() => {
    setValue(
      'Shuttles',
      isCultFit ? '04' : SHUTTLES_COUNT[selectedLevel] || '00'
    );
  }, [selectedLevel, setValue]);

  const fetchVenuList = async () => {
    toggleLoader(true);
    try {
      const getVenueAPI =
        route === 'on-college' || isAdmin ? GetOngroundVenue() : GetCultfitVenue();

      const res = await getVenueAPI;

      console.log(res, 'venue res');
      if (res.status === 200) {
        const venuData = res?.data?.Data;
        console.log(venuData, 'venuData');
        setCityListing(venuData);
      }
    } catch (err) {
      console.log(err, 'err123');
      // Handle error
    } finally {
      toggleLoader(false);
    }
  };

  const onSubmitForm = async (data) => {
    console.log(data, 'dataaaa');
    let VenueId = ''; // Initialize VenueId variable

    if (selectedVenue) {
      const matchingVenue =
        cultVenuList && cultVenuList.find((v) => v.value === selectedVenue);
      if (matchingVenue) {
        VenueId = matchingVenue.Id; // Assign the id property of the matching object to VenueId
      }
    }
    console.log(VenueId, 'VenueId');
    // If no match is found or selectedVenue is falsy, you can handle it accordingly
    try {
      dispatch(setLevelForFilter(null));
      toggleLoader(true);
      const reqData = {
        OrganizerId: userDetails?.Id,
        YoYoLevel: data.Level,
        TotalTimeTaken: `${data.Minutes}:${data.Seconds}`,
        TotalShuttle: isCultFit ? '04' : SHUTTLES_COUNT[data.Level],
        Name: data.Name,
        MobileNo: data.MobileNo,
        Venue: data.Venue,
        CreateDate: data.CreateDate,
        City: data.City,
        VenueId: VenueId || '',
      };
      
      const combinedDateTime = `${data.CreateDate}T${currentTime}:00`;
      const adminReqData = {
        UserId: "",
        Name: data.Name,
        MobileNo: data.MobileNo,
        Venue: data.Venue,
        VenueId: VenueId || '',
        // "State": "string",
        City: data.City,
        YoYoLevel: data.Level,
        Shuttels: SHUTTLES_COUNT[data.Level],
        createdate: combinedDateTime,
        TotalTimetaken: `${data.Minutes}:${data.Seconds}`,
        // Email: "string",
        IP: ipDetails && ipDetails ? ipDetails.query : "",
        Pincode: 0,
        Latitude: ipDetails && ipDetails ? ipDetails.lat : "",
        Longitude: ipDetails && ipDetails ? ipDetails.lon : "",
        StateCode: ipDetails && ipDetails ? ipDetails.region : "",
      }

      let registerAPI;
      if (route === 'on-college') {
        registerAPI = registerOnCollegeYoYoTest(reqData);
      } else if (isAdmin) {
        registerAPI = RegisterAdminOnGroundYoYoTest(adminReqData);
      } else if (isCultFit) {
        registerAPI = registerOnCultfitOnGround(reqData);
      }

      const res = await registerAPI;
      // console.log(res, 'ressss');
      if (res.status === 200) {
        if (isAdmin) {
          setAdminFormSuccess(true)
        } else {
          navigate('/leader-board');
        }
        if (userDetails?.Name) {
        } else if (!userDetails?.Name && !isAdmin) {
          updateName(data?.Name);
        }
        dispatch(
          setLevelForFilter({ level: data.Level, LeaderBoardRoute: route })
        );
        reset();
      }
    } catch (err) {
      const ErrorCode =
        err?.response?.data?.ErrorCode ||
        err?.response?.data?.ResponseCode ||
        err?.status;
      const ErrorMsg =
        err?.response?.data.Data?.[0]?.Message ||
        err?.response?.data?.Message ||
        err?.response?.data;
      toast.error(`${ErrorCode} - ${ErrorMsg}`);
    } finally {
      toggleLoader(false);
    }
  };

  const updateName = async (name) => {
    try {
      toggleLoader(true);
      const reqData = {
        Name: name || '',
        Id: userDetails?.Id || '',
      };
      const res = await updateUserName(reqData);
      // console.log(res, 'ressss');
      if (res.status === 200) {
        if (isAdmin) {
          setAdminFormSuccess(true)
        } else {
          navigate('/leader-board');
        }
      }
    } catch (err) {
      console.log(err, "error")
    } finally {
      toggleLoader(false);
    }
  };
  const handleAdminAddMoreUser = (permission) => {
    if (permission === "yes") {
      setAdminFormSuccess(false)
      reset()
    } else if (permission === "no") {
      setAdminFormSuccess(false)
      dispatch(setPromoterDetails({ promoterDetails: null }))
      navigate('/promoter')
    }
  }
  // console.log(cityOptions, 'cityOptions');
  return (
    <>
      <div className="yoyo-form on-ground-form bg-blur">
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <ul className="list py-6">
            <li className="list-item">
              <h4 className="text-primary">Name</h4>
              <div className="list-input-wrapper">
                <div className="form-group w-100">
                  <TextInput
                    inputClass="form-control-xs w-100"
                    control={control}
                    name="Name"
                    inputProps={{
                      placeholder: 'Name*',
                      readOnly: userDetails?.Name && !isAdmin ? true : false,
                    }}
                    onInput={handleKeyPressName}
                  />
                </div>
              </div>
            </li>
            <li className="list-item">
              <h4 className="text-primary">Mobile</h4>
              <div className="list-input-wrapper">
                <div className="form-group w-100">
                  <TextInput
                    inputClass="form-control-xs w-100"
                    control={control}
                    name="MobileNo"
                    inputProps={{
                      placeholder: 'Mobile*',
                      readOnly: isAdmin ? false : true,
                    }}
                    onInput={handleKeyPressMobileNumber}
                  />
                </div>
              </div>
            </li>
            {
              isAdmin ?
                <li className="list-item">
                  <h4 className="text-primary">Date</h4>
                  <div className="list-input-wrapper">
                    <div className="form-group w-100">
                      <TextInput
                        inputClass="form-control-xs w-100"
                        control={control}
                        name="CreateDate"
                        inputProps={{
                          placeholder: 'Date*',
                          readOnly: isAdmin ? false : true,
                        }}
                        type="date"
                      />
                    </div>
                  </div>
                </li>
                :
                <li className="list-item">
                  <h4 className="text-primary">Date</h4>
                  <div className="list-input-wrapper">
                    <div className="form-group w-100">
                      <TextInput
                        inputClass="form-control-xs w-100"
                        control={control}
                        name="CreateDate"
                        inputProps={{
                          placeholder: 'Date*',
                          readOnly: isAdmin ? false : true,
                        }}
                        type="text"
                      />
                    </div>
                  </div>
                </li>
            }

            {/* level  */}
            <li className="list-item">
              <h4 className="text-primary">
                {isCultFit ? 'Level' : 'Select level'}
              </h4>
              <div className="list-input-wrapper">
                <div className="form-group w-100 yoyo-select-wrap">
                  {isCultFit ? (
                    <TextInput
                      inputClass="form-control-xs w-100"
                      control={control}
                      name="Level"
                      inputProps={{
                        placeholder: 'Level*',
                        readOnly: true,
                      }}
                      type="text"
                    />
                  ) : (
                    <ReactSelectInput
                      control={control}
                      name="Level"
                      options={[
                        { label: 'Beginner', value: 'Beginner' },
                        { label: 'Intermediate', value: 'Intermediate' },
                        { label: 'Advanced', value: 'Advanced' },
                      ]}
                      inputProps={{
                        placeholder: 'Level*',
                      }}
                      isDisabled={isCultFit}
                    />
                  )}
                </div>
              </div>
            </li>

            <li className="list-item">
              <h4 className="text-primary">City</h4>
              <div className="list-input-wrapper">
                <div className="form-group w-100 yoyo-select-wrap">
                  <ReactSelectInput
                    control={control}
                    name="City"
                    options={cityOptions}
                    inputProps={{
                      placeholder: 'City*',
                    }}
                  />
                </div>
              </div>
            </li>
            {/* venue  */}
            <li className="list-item">
              <h4 className="text-primary">Venue</h4>
              <div className="list-input-wrapper">
                <div className="form-group w-100">
                  <ReactSelectInput
                    control={control}
                    name="Venue"
                    options={cultVenuList}
                    inputProps={{
                      placeholder: 'Venue*',
                    }}
                  />
                </div>
              </div>
            </li>

            {/* Enter total time taken  */}
            <li className="list-item">
              <h4 className="text-primary">Enter total time taken</h4>
              <div className="list-input-wrapper">
                <div className="form-control-wrapper position-relative">
                  <SeparateInput
                    control={control}
                    name="Minutes"
                    inputClass="form-control-xs"
                    numInputs={2}
                    placeholder="MM"
                  />
                </div>
                <p className="text-primary form-saprator px-1 px-sm-2 px-md-4 m-0 d-inline-block">
                  :
                </p>
                <div className="form-control-wrapper position-relative">
                  <SeparateInput
                    control={control}
                    name="Seconds"
                    inputClass="form-control-xs"
                    numInputs={2}
                    placeholder="SS"
                  />
                </div>
              </div>
            </li>
            {/* No. of shuttles covered  */}
            <li className="list-item">
              <h4 className="text-primary">No. of shuttles covered</h4>
              <div className="list-input-wrapper">
                <div className="form-control-wrapper">
                  <SeparateInput
                    control={control}
                    name="Shuttles"
                    inputClass="form-control-xs"
                    numInputs={2}
                    readOnly={isAdmin ? false : true}
                  />
                </div>
              </div>
            </li>
            <li className="list-item">
              <Button
                type="submit"
                className="flex-grow-0 mx-auto px-10 px-md-13">
                Submit
              </Button>
            </li>
          </ul>
        </form>

        <ReCaptcha reCaptchaRef={reCaptchaRef} />
      </div>
      <AppModal
        show={submitSuccess}
        onClose={() => setSubmitSuccess(false)}
        heading="Entry Successfully Submitted"
        headingClass="text-warning">
        <div className="text-center pt-5">
          <h3>Thank You</h3>
        </div>
      </AppModal>

      <AppModal
        show={adminFormSuccess}
        onClose={() => setAdminFormSuccess(false)}
        heading="Entry Successfully Submitted"
        headingClass="text-warning">
        <div className="text-center pt-5">
          <h3>Do You want to Add More Users ? </h3>
        </div>
        <div className="d-flex justify-content-around pt-5">
          <Button
            type="submit"
            onClick={() => handleAdminAddMoreUser("yes")}>
            Yes
          </Button>
          <Button
            type="submit"
            onClick={() => handleAdminAddMoreUser("no")}>
            No
          </Button>
        </div>

      </AppModal>
    </>
  );
}

export default TestForm;
