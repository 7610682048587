import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ProfileImageSelector from '../../components/common/profile-image-selector';
import useAuth from '../../utils/hooks/use-auth';
import useLoader from '../../utils/hooks/use-loader';
import { updateProfileImg } from '../../services/user-service';
import { fetchUserById } from '../../store/slices/auth-slice';

function ProfileImageForm() {
  const dispatch = useDispatch();
  const { userDetails } = useAuth();
  const { toggleLoader } = useLoader();

  const [selectedImg, setSelectedImage] = useState(userDetails?.ProfileImg);

  useEffect(() => {
    setSelectedImage(userDetails?.ProfileImg);
  }, [userDetails?.ProfileImg]);

  const handleChange = async (img) => {
    toggleLoader(true);
    try {
      const reqData = {
        Id: userDetails?.Id,
        ProfileImg: img,
      };
      const res = await updateProfileImg(reqData);
      if (res.status === 200) {
        // const data = res?.data?.Data;
        dispatch(fetchUserById(userDetails?.Id));
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  return (
    <>
      <ProfileImageSelector
        selected={selectedImg}
        onChange={handleChange}
        title="Change Avatar"
      />
    </>
  );
}

export default ProfileImageForm;
