import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";

import AppModal from "../../components/modals";

import { getUserClaimTime } from "../../store/slices/common-slice";
import usePopup from "../../utils/hooks/use-popup";

function UniqueCodeClaimTimeOut({ show = false, onClose }) {
  const dispatch = useDispatch();
  const { openUniqueCodePopup } = usePopup();

  const userDetails = useSelector((state) => state.auth.userDetails);
  const claimTime = useSelector((state) => state.common.claimTime);
  const timeRemain = useSelector((state) => state.common.timeRemain);

  const [open, setOpen] = useState(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    dispatch(getUserClaimTime());
  }, [dispatch, userDetails?.IsFitbitClaimTimeOver]);

  useEffect(() => {
    if (
      userDetails?.IsFitnessTrackerWon &&
      userDetails?.IsFitbitClaimTimeOver &&
      !userDetails?.IsFitbitClaim
    ) {
      setOpen(true);
    }
  }, [
    userDetails?.IsFitnessTrackerWon,
    userDetails?.IsFitbitClaimTimeOver,
    userDetails?.IsFitbitClaim,
    timeRemain,
  ]);

  const handleClose = () => {
    if (onClose && typeof onClose === "function") {
      onClose();
    }
    setOpen(false);
  };

  return (
    <AppModal show={open} onClose={handleClose} heading="Oops!">
      <>
        <p className="mt-2">
          Your claim window is over, but don't worry, many more gears are still
          up for grabs! Just try again with a new code.
        </p>
        <img src="/assets/img/products/ft.svg" alt="Fitness Tracker" />
        <h5 className="mb-1">00:00:00</h5>
        <p className="mb-4">Time Left To Claim</p>
        <Button
          className="btn btn-primary btn-block"
          onClick={() => {
            window.dataLayer.push({event: 'lmsaddnewcode'});
            if (onClose && typeof onClose === "function") {
              onClose();
            }
            setOpen(false);
            openUniqueCodePopup();
          }}
        >
          Add New Code
        </Button>
      </>
    </AppModal>
  );
}

export default UniqueCodeClaimTimeOut;
