import React, { useEffect, useState } from 'react';
import AppModal from '../../components/modals';
// import { GenerateARCertificate2 } from './ar-certificate';
import { GenerateARCertificate2 } from '../yoyo-test/ar-certificate/ar-2';

function GenerateCertificate({ show = false, onClose }) {
  const [open, setOpen] = useState(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  return (
    <AppModal
      show={open}
      onClose={handleClose}
      bodyClass="p-0"
      headerClass="p-0"
      contentClassName="p-0 bg-transparent"
      className="ar-certificate-modal">
      <GenerateARCertificate2 onSuccess={onClose} onClose={onClose} />
    </AppModal>
  );
}

export default GenerateCertificate;
