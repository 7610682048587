import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getUserById } from "../../services/user-service";

/**
 * @method fetchUserById
 * @description async thunk action to fetch user detail by id
 */
export const fetchUserById = createAsyncThunk(
  "auth/fetchUserById",
  async (userId, { getState, rejectWithValue }) => {
    const Id = userId || getState()?.auth?.userDetails?.Id;
    if (!Id) return rejectWithValue("Id required");
    const reqData = {
      Id: Id,
    };
    const res = await getUserById(reqData);
    return res.data;
  }
);

// Define the initial state for the authentication slice
const initialState = {
  isLoggedIn: false, // Indicates if the user is logged in or not
  userDetails: null, // Stores the details of the authenticated user
};

// Create the authentication slice using `createSlice`
export const authSlice = createSlice({
  name: "auth", // Name of the slice
  initialState,
  reducers: {
    // Reducer function for handling successful login
    loginSuccess: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.isLoggedIn = !!payload; // Set isLoggedIn to true if payload is not empty
      state.userDetails = state.userDetails
        ? { ...state.userDetails, ...payload }
        : payload; // Merge the user details with the existing state or set it as the new details
    },
    // Reducer function for setting user details
    setUser: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.userDetails = state.userDetails
        ? { ...state.userDetails, ...payload }
        : payload; // Merge the user details with the existing state or set it as the new details
    },
    // Reducer function for handling logout
    logoutUser: (state, action) => {
      state.isLoggedIn = false; // Set isLoggedIn to false
      state.userDetails = null; // Reset user details to null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserById.fulfilled, (state, action) => {
      state.userDetails = { ...state.userDetails, ...action.payload?.Data };
    });
  },
});

// Extract the action creators generated by `createSlice`
export const { loginSuccess, setUser, logoutUser } = authSlice.actions;

// Export the reducer function generated by `createSlice`
export default authSlice.reducer;
