import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ClaimForm from './claim-form';
import AppModal from '../../components/modals';
import ClaimSuccess from './claim-success';
import {
  CLAIM_TYPE,
  PRIVACY_POLICY_URL,
} from '../../utils/constants/common-constants';
import ClaimYoYoSuccess from './claim-yoyo-success';
import { FLOODLIGHT_COUNTER, counterFloodLight } from '../../utils/helpers/floodlight-helper';
import ErrorModel from '../../components/modals/api_error_model';
import { useDispatch } from 'react-redux';
import { setSmsLinkRedirection } from '../../store/slices/common-slice';

function Claim({ show, onClose, type = '' }) {
  const [claimSuccess, setClaimSuccess] = useState(false);
  const [showErrorModel, setShowErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClose = () => {
    onClose();
    setClaimSuccess(false);
    dispatch(setSmsLinkRedirection({ popup: '',loginJourney: false }))
  };

  const handleClaimSuccess = () => {
    setClaimSuccess(true);
  };

  if (claimSuccess) {
    if (type === CLAIM_TYPE.FIT_BIT) {
      return <ClaimSuccess show={setClaimSuccess} onClose={handleClose} />;
    } else {
      return <ClaimYoYoSuccess show={setClaimSuccess} onClose={handleClose} />;
    }
  }else if(showErrorModel){
      return <ErrorModel 
        show={showErrorModel} 
        onClose={() => {
          setShowErrorModel(false)
          dispatch(setSmsLinkRedirection({ popup: '',loginJourney: false }))
          navigate('/')
        }} 
        subHeading={errorMessage}
      />
  }

  return (
    <div>
      <AppModal
        show={show}
        onClose={handleClose}
        heading="Enter the details to claim <br/>your reward!">
        <ClaimForm onSuccess={handleClaimSuccess} type={type} showErrorModel={(msg) => {
          setShowErrorModel(true)
          setErrorMessage(msg)
        }}/>
        <Link
          className="modal-link mt-5"
          to={PRIVACY_POLICY_URL}
          onClick={() => {
            counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_PrivacyPolicy_Counter);
          }}
          target={'_blank'}>
          Privacy Policy
        </Link>
      </AppModal>
    </div>
  );
}

export default Claim;
