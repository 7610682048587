import { BRAND_NAME } from "../../config/app-config";
import { store } from "../../store";

const cdsPixelID = process.env.REACT_APP_CDS_TRACKING_CLIENT_ID;

export const getGeoData = () => {
  const ipDetails = store.getState().common.ipDetails;

  const data = {};

  if (ipDetails !== null) {
    try {
      // ip addresss
      if (ipDetails.hasOwnProperty("query")) {
        data.ipAddress = ipDetails["query"];
        // Your code that uses 'ipAddress' goes here
      } else {
        console.error("'query' property not found in 'ipDetails'");
      }

      // stateCode
      if (ipDetails.hasOwnProperty("region")) {
        data.stateCode = ipDetails["region"];
      } else {
        console.error("'region' property not found in 'ipDetails'");
      }

      // city
      if (ipDetails.hasOwnProperty("city")) {
        data.city = ipDetails["city"];
      } else {
        console.error("'city' property not found in 'ipDetails'");
      }

      // zip
      if (ipDetails.hasOwnProperty("zip")) {
        data.zip = ipDetails["zip"];
      } else {
        console.error("'zip' property not found in 'ipDetails'");
      }
    } catch (error) {
      console.error("Error parsing 'ipDetails' JSON:", error);
    }
  } else {
    console.error("'ipDetails' key not found in localStorage");
  }

  console.log("data: ", data);
  return data;
};

export const clickButtonCDP = async (eventSubType) => {
  const data = {
    event_type: "Click",
    event_sub_type: `${eventSubType}`,
    brand_name: BRAND_NAME,
  };
  console.log("cdp clickbutton", cdsPixelID, data);
  window.cds_pixel(cdsPixelID, data);
};

export const onLoadCDP = async (eventSubType) => {
  const data = {
    event_type: "On load",
    event_sub_type: `${eventSubType}`,
    brand_name: BRAND_NAME,
  };
  console.log("cdp onload", cdsPixelID, data);
  window.cds_pixel(cdsPixelID, data);
};

export const HomePageCDP = async () => {
  const data = {
    event_type: "Page_view",
    event_sub_type: "Homepage_load",
    brand_name: BRAND_NAME,
  };
  const ipData = getGeoData();

  const geoData = {
    geo_country_code: "IN",
    geo_state_province_code: ipData["stateCode"],
    geo_city_name: ipData["city"],
    geo_postal_code: ipData["zip"],
    ip_address: ipData["ipAddress"],
  };

  const dataCdp = { ...data, ...geoData };
  console.log("cdp Home", dataCdp);
  window.cds_pixel(cdsPixelID, dataCdp);
};

export const BuyNowCDP = async (eventSubType, partnerName) => {
  const data = {
    event_type: "Click",
    event_sub_type: `${eventSubType}`,
    partner_name: `${partnerName}`,
    brand_name: BRAND_NAME,
  };
  console.log("cdp buynow", cdsPixelID, data);
  window.cds_pixel(cdsPixelID, data);
};
