import toast from "react-hot-toast";
import { store } from ".././../store";
import { logoutUser } from "../../store/slices/auth-slice";
/**
 * @method apiErrorHandler
 * @description handle api error
 */
export const apiErrorHandler = (res) => {
  const response = res ? res.response : undefined;

  if (response && response.data && response.status === 401) {
    if (response && response.data && response.data.error) {
      toast.error("You are not authorized", {
        id: "unauthorize",
      });
      store.dispatch(logoutUser());
      localStorage.clear();
      sessionStorage.clear();
      window.location.assign("/");
    } else {
      toast.error(response.data.Message, { id: "unauthorize" });
    }
  } else if (response && response.status === 400) {
    // toast.error(response.data.Message, {
    //   id: "err_400",
    // });
  } else if (response && response.status === 409) {
    toast.error(response.data && response.data.Message, {
      id: "err_409",
    });
  } else if (response && response.status >= 500) {
    toast.error("Something went wrong please try again later", {
      id: "some-went-wrong",
    });
  }
};
