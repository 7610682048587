import React, { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import AppModal from "../../components/modals";
import useAuth from "../../utils/hooks/use-auth";
import { handleShare } from "../../utils/helpers";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../utils/helpers/floodlight-helper";

function ClaimSuccess({ show, onClose }) {
  const { userDetails } = useAuth();
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
    navigate("/yoyo");
  };

  const handleShareTicket = useCallback(() => {
    counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_Share_Counter);
    const dataToShare = {
      title: "I just won an all-new Fitness Tracker! You can too!",
      text: "Just grab a bottle of Limca® Sportz and get playing! ",
      image: "/assets/img/social-share/share-tfitness-tracker.jpg",
      url: "Visit https://lmsicc.coke2home.com/",
    };
    handleShare(dataToShare);
  }, []);

  const renderActions = () => {
    if (userDetails?.IsShortlisted) return null;
    else if (userDetails?.IsYoYoTicketClaim) return null;

    return (
      <>
        <p className="my-3">
          Here’s your shot at winning the ICC Men's Cricket <br />
          World CUP 2023 Match Tickets*!
        </p>
        <Button className="btn btn-primary btn-block" onClick={handleClose}>
          Take the Yo-Yo Test
        </Button>
      </>
    );
  };

  return (
    <div>
      <AppModal
        show={show}
        onClose={onClose}
        headingClass="text-primary"
        heading="Congratulations Champ!"
      >
        <p className="mt-3">
          You’ve won a Fitness Tracker! Your gear will be delivered to you
          <br />
          within 28 business days
        </p>
        <img
          className="w-50"
          src="/assets/img/products/share-fit-bit.png"
          alt="Share-fit-bit"
        />
        <h6 className="mt-0 mb-3">
          Ab RukkMatt, tell your gang about the win!
        </h6>
        <div className="social-link">
          <a href="/assets/img/social-share/Fitness_tacker.png" download>
            <img
              className="icon"
              src="/assets/img/icons/download.svg"
              alt="Ticket"
            />
          </a>
          <Link>
            <img
              className="icon"
              src="/assets/img/icons/Share.svg"
              alt="Ticket"
              onClick={handleShareTicket}
            />
          </Link>
        </div>

        {renderActions()}
      </AppModal>
    </div>
  );
}

export default ClaimSuccess;
