import React from "react";
import AppModal from "../../../components/modals";

import Recorder from "./recorder";

function YoYoTestRecording({ show, onClose, Level }) {
  return (
    <>
      <AppModal
        show={show}
        onClose={onClose}
        bodyClass="max-w-none"
        backdrop="static"
      >
        <Recorder onSuccess={onClose} Level={Level} />
      </AppModal>
    </>
  );
}

export default YoYoTestRecording;
