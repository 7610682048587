import React from 'react';
import { components } from 'react-select';

function SelectDropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <img src="/assets/img/icons/dropdown-arrow.svg" alt="" />
    </components.DropdownIndicator>
  );
}

export default SelectDropdownIndicator;
