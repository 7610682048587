import { async } from "q";
import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";

export const GetCultOnGroundDataList = async (data) => {
  return await AxiosInstance.post(API_END_POINT.GetCultOnGroundDataList, data);
};

export const GetOnGroundDataList = async (data) => {
  return await AxiosInstance.post(API_END_POINT.GetOnGroundDataList, data);
};

export const GetEmployeeOnGroundDataList = async (data) => {
  return await AxiosInstance.post(
    API_END_POINT.GetEmployeeOnGroundDataList,
    data
  );
};

export const GetFitbitDataList = async (data) => {
  return await AxiosInstance.post(API_END_POINT.GetFitbitDataList, data);
};

export const GetYoyoticketDataList = async (data) => {
  return await AxiosInstance.post(API_END_POINT.GetYoyoticketDataList, data);
};

export const RegisterAdminOnGroundYoYoTest = async (data) => {
  return await AxiosInstance.post(
    API_END_POINT.RegisterAdminOnGroundYoYoTest,
    data
  );
};
