import React, { useState } from 'react';
import AdminDashboard from '../components/admin';
import AdminLogin from '../components/admin/admin-login-popup';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import ReportTableContent from '../components/admin/report-table';

const Admin = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const isAdminLogin = useSelector((state) => (state?.common?.adminDetails))
    const navigate = useNavigate()

    const handleLoginSuccess = () => {
        navigate("/admin")
    };

    const handleClose = () => {
        setLoggedIn(false)
    }
    return (
        <>
            <div>
                {isAdminLogin?.adminDetails ? (
                    <ReportTableContent />
                    // <ReportTableContent />
                ) : (
                    <AdminLogin setLoggedIn={setLoggedIn} handleClose={handleClose} onLoginSuccess={handleLoginSuccess} route="admin"/>
                )}
            </div>
        </>
    )
}

export default Admin