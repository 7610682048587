import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Toaster } from "react-hot-toast";

import PublicLayout from "./components/layouts/public-layout";
import {
  fetchIPDetails,
  getUserClaimTime,
  setSmsLinkRedirection,
} from "./store/slices/common-slice";
import { LoaderProvider } from "./utils/context";
import { PopupProvider } from "./utils/context/popup-context";
import { fetchUserById } from "./store/slices/auth-slice";
import { useLocation } from "react-router-dom";
import { preventRightClick } from "./utils/helpers";

function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const popup = queryParams.get("popup");

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      preventRightClick();
    }
  }, []);

  useEffect(() => {
    // fetching ip detail action
    dispatch(fetchIPDetails());
    dispatch(fetchUserById());
    dispatch(getUserClaimTime());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUserById());
  }, [pathname, dispatch]);

  useEffect(() => {
    if (popup) {
      dispatch(setSmsLinkRedirection({ popup: popup, loginJourney: false }));
    } else {
      dispatch(setSmsLinkRedirection({ popup: "", loginJourney: false }));
    }
  }, [popup]);

  return (
    <div className="App">
      <LoaderProvider>
        <PopupProvider>
          <PublicLayout />
          <Toaster position="top-right" />
        </PopupProvider>
      </LoaderProvider>
    </div>
  );
}

export default App;
