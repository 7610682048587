import React, { useState } from "react";
import AppModal from "../../components/modals";
import { Link } from "react-router-dom";
import { TERMS_AND_CONDITIONS_URL } from "../../utils/constants";
import { clickButtonCDP } from "../../utils/helpers/cds-helper";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../utils/helpers/floodlight-helper";

function YoYoTestInstructions() {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    clickButtonCDP("HowToWin_ICCTickets");
  };

  return (
    <>
      <Link className="btn btn-primary" onClick={handleOpen}>
        read instructions
      </Link>
      <AppModal
        show={open}
        onClose={handleClose}
        bodyClass="max-w-none"
        size="lg"
      >
        <img
          className="d-none d-sm-block"
          src="/assets/img/common/instructions.png"
          alt="instructions"
        />
        <img
          className="d-block d-sm-none"
          src="/assets/img/common/Instruction-m.png"
          alt="instructions"
        />
        <p className="small mt-4 mt-sm-5">
          For more details, read the{" "}
          <Link target="_blank" to={TERMS_AND_CONDITIONS_URL}        onClick={() => {
            counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_TC_Counter);
          }}>
            T&C
          </Link>{" "}
          carefully.
        </p>
      </AppModal>
    </>
  );
}

export default YoYoTestInstructions;
