import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Button } from 'react-bootstrap';

import { downloadFileByAnchor } from '../../../utils/helpers';
import { generateARCertificate } from '../../../services/yoyo-service';
import useAuth from '../../../utils/hooks/use-auth';
import useLoader from '../../../utils/hooks/use-loader';
import useTimer from '../../../utils/hooks/use-timer';

const overlayImage2 = '/assets/img/common/certificate3.png';
const Webcam = window.Webcam;

export const GenerateARCertificate2 = ({ onSuccess, onClose }) => {
  const [certImage, setCertImage] = useState('');
  const { toggleLoader } = useLoader();
  const { userDetails } = useAuth();

  const [facingMode, setFacingMode] = useState('user'); // "user" for front camera, "environment" for back camera
  const [showTimer, setShowTimer] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const { time, startTimer, pauseTimer, resetTimer, formattedTimerTime } =
    useTimer(10, true);

  useEffect(() => {
    Webcam.set({
      image_format: 'jpeg',
      jpeg_quality: 100,
      width: 480,
      height: 720,
      dest_width: 480,
      dest_height: 720,
      flip_horiz: true,
      constraints: {
        video: {
          facingMode: facingMode, // or "environment" for rear camera
          width: { ideal: 480 },
          height: { ideal: 720 },
        },
      },
    });

    Webcam.attach('#camera');
  }, [facingMode]);

  useEffect(() => {
    if (time === 0) {
      setShowTimer(false);
      capture();
    }
  }, [time]);

  const reportARCertificateGenerated = useCallback(async () => {
    toggleLoader(true);
    try {
      await generateARCertificate({ Id: userDetails?.Id });
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  }, [toggleLoader, userDetails?.Id]);

  const capture = () => {
    window.dataLayer.push({ event: "lmsyoyocertificatecapture" });
    var video = document.getElementById('video');
    var canvas = document.getElementById('canvas');

    Webcam.snap(function (data_uri, canvas, context) {
      video = new Image();
      video.src = data_uri;

      // Reset the canvas transformation
      context.setTransform(1, 0, 0, 1, 0, 0);

      var overlay = document.getElementById('overlay');
      context.drawImage(overlay, 0, 0, canvas.width, canvas.height);

      // Convert the canvas image to a data URL
      var dataURL = canvas.toDataURL();

      setCertImage(dataURL);
    }, canvas);
  };

  const save = () => {
    window.dataLayer.push({ event: 'lmsyoyocertificatesave' });
    downloadFileByAnchor(certImage, 'YOYO_CERTIFICATE', 'jpeg');
    reportARCertificateGenerated();
    onSuccess();
  };

  if (certImage) {
    return (
      <>
        <img src={certImage} alt="Certificate" />
        <div className="btn-block">
          <button
            onClick={() => {
              save();
            }}
            className="btn btn-primary btn-sm">
            Save
          </button>
          <button
            onClick={() => setCertImage('')}
            className="btn btn-primary btn-sm">
            Retake
          </button>
        </div>
      </>
    );
  }

  const toggleFacingMode = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  const renderSwitchCam = () => {
    if (!isMobile) return null;

    return (
      <Button onClick={toggleFacingMode} disabled={showTimer}>
        Switch Camera
      </Button>
    );
  };

  const startCountDown = () => {
    setShowTimer(true);
    resetTimer();
    startTimer();
  };
  const handleToggle = () => {
    setIsOn(!isOn);
    setShowTimer(isOn);
  };
  return (
    <>
      <div className="wrapper">
        <div id="camera" className="mx-auto"></div>
        <canvas id="canvas"></canvas>

        <img id="overlay" src={overlayImage2} alt="Cutout Neeraj Chopra" />
        {showTimer && time > 0 && (
          <h2
            className="count-down-timer display-1"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}>
            {time}
          </h2>
        )}
        <div className="btn-block">
          <button
            onClick={handleToggle}
            className="btn btn-primary btn-sm text-nowrap">
            {isOn ? 'Timer ON' : 'Timer OFF'}
          </button>
          {isOn ? (
            <button
              onClick={startCountDown}
              className="btn btn-primary btn-sm"
              disabled={showTimer}>
              Capture
            </button>
          ) : (
            <button onClick={capture} className="btn btn-primary btn-sm">
              Capture
            </button>
          )}
          {renderSwitchCam()}
        </div>
      </div>
    </>
  );
};

export default GenerateARCertificate2;
