import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import SideNav from '../nav/side-nav/side-nav';
import ProfileMenu from '../profile';
import useAuth from '../../utils/hooks/use-auth';
import usePopup from '../../utils/hooks/use-popup';
import { useDispatch, useSelector } from 'react-redux';
import { RESTRICTED_STATE, ELIGIBLE_COUNTRY } from '../../utils/constants';
import {
  FLOODLIGHT_COUNTER,
  counterFloodLight,
} from '../../utils/helpers/floodlight-helper';
import { useLocation } from 'react-router-dom';
import { setLevelForFilter, setSmsLinkRedirection } from '../../store/slices/common-slice';
import {
  CLAIM_TYPE
} from '../../utils/constants/common-constants';
import ClaimYoYoSuccess from '../../features/claim/claim-yoyo-success';
import UniqueCodeSuccess from '../../features/unique-code/unique-code-success';
import YoYoEntrySuccess from "../../features/yoyo-test/yoyo-entry-success";


function Header() {
  const navigate = useNavigate();
  const ipDetails = useSelector((state) => state.common.ipDetails);
  const { userDetails } = useAuth()
  const dispatch = useDispatch();
  const [showRegisterModel, setShowRegisterModel] = useState(false)
  const restrictToRedirect =
    ipDetails && ipDetails.regionName === RESTRICTED_STATE ? true : false;
  const isCountrynotIndia = ipDetails && ipDetails.country !== ELIGIBLE_COUNTRY;
  console.log(userDetails, "userDetails")
  const formDetails = useSelector((state) => state.common?.level)

  const isOnGroundtest = userDetails && userDetails?.IsOnGroundtest;
  const IsCultfitongroundtest = userDetails && userDetails?.IsCultfitongroundtest;
  const alredyFillForm = isOnGroundtest || IsCultfitongroundtest;

  const isLeaderBoardVisible = formDetails?.LeaderBoardRoute || alredyFillForm
  console.log(isLeaderBoardVisible, "isLeaderBoardVisible")
  const smsLinkRedirection = useSelector((state) => state?.common?.smsLinkRedirection)
  let loginJourney = smsLinkRedirection && smsLinkRedirection.loginJourney
  let popup = smsLinkRedirection && smsLinkRedirection.popup
  const [claimSuccess, setClaimSuccess] = useState(false);
  const [codeSuccess, setCodeSuccess] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const { openLoginPopup, openRestrictRegionPopup, openGenerateARCertificatePopup, openClaimPopup } = usePopup();
  const { isLoggedIn } = useAuth();
  const { pathname } = useLocation();
  const hideSection =
    pathname === '/leader-board' ||
    pathname === '/on-ground' ||
    pathname === '/on-college' ||
    pathname === '/cultfit-onground' ||
    pathname === '/admin' ||
    pathname === "/admin-leaderBoard";

  useEffect(() => {
    if (smsLinkRedirection && smsLinkRedirection.popup) {
      if (smsLinkRedirection.popup === 'login') {
        openLoginPopup();
      }
      if (isLoggedIn) {
        if (smsLinkRedirection.popup === 'download_certificate') {
          openGenerateARCertificatePopup()
        } else if (smsLinkRedirection.popup === 'LMS_ClaimTicket') {
          console.log('case3')
          openClaimPopup(CLAIM_TYPE.YOYO_TICKET)()
        } else if (smsLinkRedirection.popup === 'LMS_WonTicket') {
          setClaimSuccess(true)
        } else if (smsLinkRedirection.popup === 'LMS_GenARcertificate') {
          console.log('popup', smsLinkRedirection.popup)
          openGenerateARCertificatePopup()
        } else if (smsLinkRedirection.popup === 'LMS_UniquecodeSuccess') {
          setCodeSuccess(true)
        } else if (smsLinkRedirection.popup === 'LMS_yoyo_entry_success') {
          setOpenSuccess(true)
        }
      } else {
        openLoginPopup();
      }
    }
  }, [popup, loginJourney])// eslint-disable-line react-hooks/exhaustive-deps


  console.log(hideSection, 'hideSection');
  const handleLogin = () => {
    counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_Login_Counter);
    if (restrictToRedirect || isCountrynotIndia) {
      openRestrictRegionPopup();
    } else {
      openLoginPopup();
    }
  };
  const handleLeaderBoard = () => {
    const cleanedPathname = pathname && pathname.substring(1);
    const filterLevel = cleanedPathname === "cultfit-onground" ? "Beginner" : null
    dispatch(
      setLevelForFilter({ level: filterLevel, LeaderBoardRoute: cleanedPathname })
    );
    navigate('/leader-board');
  }
  console.log(pathname, "pathname")
  return (
    <header className="header">
      <div className="container-fluid">
        <div className="header-nav">
          <SideNav />
          <Link to="/" className="header-logo">
            <img src="/assets/img/common/logo.png" alt="Limca logo" />
          </Link>
          {isLoggedIn ? (
            <div className="profile-wrapper">
              {!hideSection ? (
                <Button
                  className="p-0 mr-md-12 mr-3 yr-100-center"
                  variant="link btn-link-win"
                  onClick={() => {
                    navigate('/my-winnings');
                  }}>
                  My Winnings
                </Button>
              ) : null}
              {
                pathname !== "/leader-board" && pathname !== "/admin" && pathname !== "/admin-leaderBoard" && isLeaderBoardVisible && hideSection &&
                <Button
                  className="p-0 mr-md-12 mr-3 yr-100-center"
                  variant="link btn-link-win"
                  onClick={() => {
                    handleLeaderBoard()
                  }}>
                  Leaderboard
                </Button>
              }
              {
                pathname !== "/admin" && pathname !== "/admin-leaderBoard" &&
                <ProfileMenu />
              }
            </div>
          ) : (
            pathname !== "/admin" && pathname !== "/admin-leaderBoard" && pathname !=="/promoter" &&
            <Button
              className="p-0 tu-wallet-link"
              variant="link"
              onClick={handleLogin}>
              Login
            </Button>
          )}
        </div>
      </div>
      {claimSuccess && <ClaimYoYoSuccess show={claimSuccess} onClose={() => {
        setClaimSuccess(false)
        dispatch(setSmsLinkRedirection({ popup: '',loginJourney: false }))
      }} />}
      {codeSuccess &&
        <UniqueCodeSuccess show={codeSuccess} onClose={() => {
          setCodeSuccess(false)
          dispatch(setSmsLinkRedirection({ popup: '',loginJourney: false }))
        }}
        />
      }
      <YoYoEntrySuccess
        show={openSuccess}
        onClose={() => {
          navigate("/my-winnings");
          setOpenSuccess(false);
          dispatch(setSmsLinkRedirection({ popup: '',loginJourney: false }))
        }}
      />
    </header>
  );
}

export default Header;
