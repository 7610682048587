import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';

import {
  ReactSelectInput,
  SeparateInput,
  TextInput,
} from '../../utils/form-inputs';
import AppModal from '../../components/modals';
import { yoyoOnGroundTestFormValidationSchema } from '../../utils/validations';
import useAuth from '../../utils/hooks/use-auth';
import useLoader from '../../utils/hooks/use-loader';
import ReCaptcha from '../../components/common/recaptcha';
import { SHUTTLES_COUNT } from '../../utils/constants/common-constants';
import { registerOnGroundYoYoTest } from '../../services/yoyo-service';
import {
  getCityList,
  getStateList,
  handleKeyPressMobileNumber,
  handleKeyPressName,
} from '../../utils/helpers';

function OnGroundTestFrom({ onSuccess }) {
  const { userDetails } = useAuth();
  const { toggleLoader } = useLoader();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const reCaptchaRef = useRef();

  const stateList = getStateList();

  const stateOptions = useMemo(() => {
    return stateList.map((s) => ({ label: s.name, value: s.name }));
  }, [stateList]);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState,
    trigger,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(yoyoOnGroundTestFormValidationSchema),
    defaultValues: {
      Name: '',
      MobileNo: '',
      Venue: '',
      CreateDate: dayjs().format('YYYY-MM-DD') || '',
      State: '',
      City: '',
      Minutes: '',
      Seconds: '',
      Level: 'Beginner',
      Shuttles: '02',
    },
    mode: 'all',
  });

  console.log('formState errors: ', formState.errors);
  console.log('getValues: ', getValues());
  const selectedLevel = watch('Level');
  const minutesEntered = watch('Minutes');
  const selectedState = watch('State');

  const cityOptions = useMemo(() => {
    if (!selectedState) return [];

    const stateDetail = stateList.find((s) => s.name === selectedState);
    if (!stateDetail) return [];

    const cities = getCityList(stateDetail.isoCode);
    return cities.map((c) => ({ label: c.name, value: c.name }));
  }, [selectedState, stateList]);

  useEffect(() => {
    // re-set city when state change
    setValue('City', '');
  }, [selectedState, setValue]);

  useEffect(() => {
    if (minutesEntered) {
      trigger('Seconds');
    }
  }, [minutesEntered, trigger]);

  useEffect(() => {
    setValue('Shuttles', SHUTTLES_COUNT[selectedLevel] || '00');
  }, [selectedLevel, setValue]);

  const handleSuccess = () => {
    setSubmitSuccess(true);
  };
  const onSubmitForm = async (data) => {
    console.log('data: ', data);
    try {
      toggleLoader(true);

      const reqData = {
        OrganizerId: userDetails?.Id,
        YoYoLevel: data.Level,
        TotalTimeTaken: `${data.Minutes}:${data.Seconds}`,
        TotalShuttle: SHUTTLES_COUNT[data.Level],
        Name: data.Name,
        MobileNo: data.MobileNo,
        Venue: data.Venue,
        CreateDate: data.CreateDate,
        City: data.City,
        State: data.State,
      };

      console.log('reqData: ', reqData);

      const res = await registerOnGroundYoYoTest(reqData);
      if (res.status === 200) {
        // onSuccess();
        handleSuccess();
        reset();
      }
    } catch (err) {
      const ErrorCode =
        err?.response?.data?.ErrorCode ||
        err?.response?.data?.ResponseCode ||
        err?.status;
      const ErrorMsg =
        err?.response?.data.Data?.[0]?.Message ||
        err?.response?.data?.Message ||
        err?.response?.data;
      toast.error(`${ErrorCode} - ${ErrorMsg}`);
    } finally {
      toggleLoader(false);
    }
  };

  return (
    <>
      <div className="yoyo-form on-ground-form bg-blur">
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <ul className="list py-6">
            <li className="list-item">
              <h4 className="text-primary">Name</h4>
              <div className="list-input-wrapper">
                <div className="form-group w-100">
                  <TextInput
                    control={control}
                    name="Name"
                    inputProps={{
                      placeholder: 'Name*',
                    }}
                    onInput={handleKeyPressName}
                  />
                </div>
              </div>
            </li>
            <li className="list-item">
              <h4 className="text-primary">Mobile</h4>
              <div className="list-input-wrapper">
                <div className="form-group w-100">
                  <TextInput
                    control={control}
                    name="MobileNo"
                    inputProps={{
                      placeholder: 'Mobile*',
                    }}
                    onInput={handleKeyPressMobileNumber}
                  />
                </div>
              </div>
            </li>

            <li className="list-item">
              <h4 className="text-primary">Date</h4>
              <div className="list-input-wrapper">
                <div className="form-group w-100">
                  <TextInput
                    control={control}
                    name="CreateDate"
                    inputProps={{
                      placeholder: 'Date*',
                    }}
                    type="date"
                  />
                </div>
              </div>
            </li>

            <li className="list-item">
              <h4 className="text-primary">Venue</h4>
              <div className="list-input-wrapper">
                <div className="form-group w-100">
                  <TextInput
                    control={control}
                    name="Venue"
                    inputProps={{
                      placeholder: 'Venue*',
                    }}
                  />
                </div>
              </div>
            </li>

            <li className="list-item">
              <h4 className="text-primary">State</h4>
              <div className="list-input-wrapper">
                <div className="form-group w-100 yoyo-select-wrap">
                  <ReactSelectInput
                    control={control}
                    name="State"
                    options={stateOptions}
                    inputProps={{
                      placeholder: 'State*',
                    }}
                  />
                </div>
              </div>
            </li>

            <li className="list-item">
              <h4 className="text-primary">City</h4>
              <div className="list-input-wrapper">
                <div className="form-group w-100 yoyo-select-wrap">
                  <ReactSelectInput
                    control={control}
                    name="City"
                    options={cityOptions}
                    inputProps={{
                      placeholder: 'City*',
                    }}
                  />
                </div>
              </div>
            </li>

            <li className="list-item">
              <h4 className="text-primary">Select level</h4>
              <div className="list-input-wrapper">
                <div className="form-group w-100 yoyo-select-wrap">
                  <ReactSelectInput
                    control={control}
                    name="Level"
                    options={[
                      { label: 'Beginner', value: 'Beginner' },
                      { label: 'Intermediate', value: 'Intermediate' },
                      { label: 'Advanced', value: 'Advanced' },
                    ]}
                    inputProps={{
                      placeholder: 'Level*',
                    }}
                  />
                </div>
              </div>
            </li>

            <li className="list-item">
              <h4 className="text-primary">Enter total time taken</h4>
              <div className="list-input-wrapper">
                <div className="form-control-wrapper position-relative">
                  <SeparateInput
                    control={control}
                    name="Minutes"
                    inputClass="form-control-xs"
                    numInputs={2}
                    placeholder="MM"
                  />
                </div>
                <p className="text-primary form-saprator px-1 px-sm-2 px-md-4 m-0 d-inline-block">
                  :
                </p>
                <div className="form-control-wrapper position-relative">
                  <SeparateInput
                    control={control}
                    name="Seconds"
                    inputClass="form-control-xs"
                    numInputs={2}
                    placeholder="SS"
                  />
                </div>
              </div>
            </li>
            <li className="list-item">
              <h4 className="text-primary">No. of shuttles covered</h4>
              <div className="list-input-wrapper">
                <div className="form-control-wrapper">
                  <SeparateInput
                    control={control}
                    name="Shuttles"
                    inputClass="form-control-xs"
                    numInputs={2}
                    readOnly
                  />
                </div>
              </div>
            </li>
            <li className="list-item">
              <Button
                type="submit"
                className="flex-grow-0 mx-auto px-10 px-md-13">
                Submit
              </Button>
            </li>
          </ul>
        </form>

        <ReCaptcha reCaptchaRef={reCaptchaRef} />
      </div>

      <AppModal
        show={submitSuccess}
        onClose={() => setSubmitSuccess(false)}
        heading="Entry Successfully Submitted"
        headingClass="text-warning">
        <div className="text-center pt-5">
          <h3>Thank You</h3>
        </div>
      </AppModal>
    </>
  );
}

export default OnGroundTestFrom;
