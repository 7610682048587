import { useState, useEffect } from "react";

import { calculateTimeRemainingToClaim } from "../../utils/helpers";

const useCountdown = (winTime) => {
  const { formatTime } = calculateTimeRemainingToClaim(winTime);

  const [timeRemaining, setTimeRemaining] = useState(0);

  useEffect(() => {
    setTimeRemaining(calculateTimeRemainingToClaim(winTime)?.time);
  }, [winTime]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => Math.max(0, prevTime - 1));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return formatTime(timeRemaining);
};

export default useCountdown;
