import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { RecordRTCPromisesHandler } from "recordrtc";
import { isMobile } from "react-device-detect";

import { setRecording } from "../../../store/slices/common-slice";
import { isBlob, isGetUserMediaSupported } from "../../../utils/helpers";
import useTimer from "../../../utils/hooks/use-timer";
import { VIDEO_TIME_DURATION } from "../../../utils/constants/common-constants";

const videoConstraints = {
  width: { ideal: 1280 },
  height: { ideal: 720 },
  aspectRatio: 16 / 9,
};

function Recorder({ onSuccess, Level }) {
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const recorderRef = useRef(null);

  const recordedBlob = useSelector((state) => state.common.recording);

  const [isRecording, setIsRecording] = useState(false);
  const [videoBlob, setVideoBlob] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [facingMode, setFacingMode] = useState("user"); // "user" for front camera, "environment" for back camera
  const [isRecordingSupported, setIsRecordingSupported] = useState(
    isGetUserMediaSupported()
  );
  const [rError, setRError] = useState("");

  const { time, startTimer, pauseTimer, resetTimer, formattedTimerTime } =
    useTimer();

  const getStream = useCallback(async () => {
    try {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error("getUserMedia is not supported in this environment.");
      }

      // Stop the current media stream (if any)
      if (videoRef?.current?.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        video: { videoConstraints, facingMode },
        audio: false,
      });

      return stream;
    } catch (error) {
      return null;
    }
  }, [facingMode]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.onplaying = function () {
        setPlaying(true);
      };
      videoRef.current.onended = function () {
        setPlaying(false);
      };
    }
  }, []);

  useEffect(() => {
    let stream = null;
    if (!isGetUserMediaSupported()) {
      // Handle the case where getUserMedia is not supported
      setIsRecordingSupported(false);
    } else {
      dispatch(setRecording(null));
      async function openCam() {
        stream = await getStream();
        if (stream) {
          videoRef.current.srcObject = stream;
        }
      }
      openCam();
    }
    return () => {
      // Clean up the media stream when the component unmounts
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [getStream, dispatch]);

  const toggleFacingMode = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  const startRecording = async () => {
    try {
      resetTimer();
      let stream = await getStream();
      if (!stream) {
        stream = await getStream();
        if (stream) {
          videoRef.current.srcObject = stream;
        } else {
          return;
        }
      } else {
        videoRef.current.srcObject = stream;
      }

      setIsRecording(true);
      setRError("");

      let mimeType = "video/mp4";

      if (navigator?.userAgent?.indexOf("Chrome") !== -1) {
        console.log("Chrome");
        mimeType = "video/webm;codecs=vp9";
        console.log(" mimeType: " + mimeType);
      }

      recorderRef.current = new RecordRTCPromisesHandler(stream, {
        type: "video",
        mimeType: mimeType,
        videoBitsPerSecond: 128000,
        timeSlice: 1000,
        frameRate: 30,
        bitrate: 128000,
      });

      recorderRef.current.camera = stream;
      startTimer();
      recorderRef.current.startRecording();
    } catch (error) {
      console.log("start recording error: ", error);
    }
  };

  const stopRecording = async () => {
    try {
      pauseTimer();

      if (recorderRef.current) {
        videoRef.current.src = videoRef.current.srcObject = null;
        videoRef.current.muted = true;
        videoRef.current.volume = 0;

        await recorderRef.current.stopRecording();

        let oldBlob = await recorderRef.current.getBlob();
        if (!oldBlob) {
          oldBlob = await recorderRef.current.blob;
        }
        const blob = new Blob([oldBlob], { type: "video/mp4" });

        videoRef.current.src = URL.createObjectURL(blob);
        setVideoBlob(blob);

        recorderRef.current.camera.stop();
        recorderRef.current.destroy();
        recorderRef.current = null;

        setIsRecording(false);

        const maxAllowedDuration = VIDEO_TIME_DURATION[Level];
        if (time < maxAllowedDuration) {
          dispatch(setRecording(null));
          setRError(`Min ${maxAllowedDuration} sec duration required`);
          return;
        } else {
          dispatch(setRecording(blob));
        }
      }
    } catch (err) {
      recorderRef.current.camera.stop();
      recorderRef.current.destroy();
      recorderRef.current = null;
      setIsRecording(false);
      setVideoBlob(null);
      dispatch(setRecording(null));

      console.log("stope err: ", err);
    }
  };

  const handleStartRecording = () => {
    if (!isRecording) {
      startRecording();
    }
  };

  const renderSwitchCam = () => {
    if (!isMobile) return null;
    if (isRecording) return null;
    else if (videoBlob) return null;

    return (
      <Button onClick={toggleFacingMode} className="btn-block">
        Switch Camera
      </Button>
    );
  };

  if (!isRecordingSupported) {
    return <h5>Recording is not supported in this browser.</h5>;
  }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-11">
          <div className="yoyo-video">
            <video
              src=""
              autoPlay
              muted
              ref={videoRef}
              playsInline
              webkit-playsInline
            />
            {!playing && recordedBlob && (
              <img
                src="/assets/img/icons/play-icon.svg"
                alt="Play"
                onClick={() => {
                  videoRef.current.play();
                }}
                className="xy-center"
              />
            )}
          </div>
        </div>
      </div>
      <h5 className="my-1">{formattedTimerTime}</h5>
      {rError ? <h6 className="my-1">{rError}</h6> : null}
      <div className="row justify-content-center actions my-2">
        <div className="col-8">
          {renderSwitchCam()}

          {isRecording ? (
            <Button
              onClick={stopRecording}
              disabled={!isRecording}
              className="btn-block"
            >
              Stop Recording...
            </Button>
          ) : (
            <Button
              onClick={handleStartRecording}
              disabled={isRecording}
              className="btn-block"
            >
              {videoBlob ? "Retake" : "Record"}
            </Button>
          )}

          {videoBlob && isBlob(videoBlob) && !isRecording && !rError && (
            <Button
              onClick={() => {
                onSuccess();
                window.dataLayer.push({ event: "lmsrecordsubmityoyotest" });
              }}
              disabled={!videoBlob}
              className="my-2 btn-block"
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default Recorder;
