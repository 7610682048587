import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactTable from "../common/table";
import { commonColumns, fitBitColumns, winnerListDummydata, yoyoTicketColumns } from "../../utils/constants";
import useLoader from "../../utils/hooks/use-loader";
import { useSelector } from "react-redux";
import { GetCultOnGroundDataList, GetEmployeeOnGroundDataList, GetFitbitDataList, GetOnGroundDataList, GetYoyoticketDataList } from "../../services/admin-service";
import { ReactSelectInput, TextInput } from "../../utils/form-inputs";
import { useForm } from "react-hook-form";
import { GetCultfitVenue, GetOngroundVenue } from "../../services/winner-service";
import { Button } from 'react-bootstrap';

const ReportTableContent = () => {
    const [tableData, setTableData] = useState();
    const [totalCount, setTotalCount] = useState()
    const [venueListing, setVenueListing] = useState([])
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const { toggleLoader } = useLoader();
    const { handleSubmit, control, watch, setValue, reset } = useForm({
        defaultValues: {
            Level: "Select Level",
        },
    });
    const report = useSelector((state) => (state?.common?.adminDetails))
    const isReport = report && report?.adminReportRoute

    const reportColumn =
        isReport === "GetFitbitDataList"
            ? fitBitColumns
            : isReport === "GetYoyoticketDataList"
                ? yoyoTicketColumns
                : commonColumns;

    let level = watch('Level');
    let startDate = watch("StartDate")
    let venue = watch('Venue')
    let endDate = watch('EndDate')
    const levelFilterEnable = isReport === "GetCultOnGroundDataList" || isReport === "GetOnGroundDataList"
        || isReport === "GetEmployeeOnGroundDataList"
    const venuFilterEnable = isReport === "GetCultOnGroundDataList" || isReport === "GetOnGroundDataList"
    const getReportDetails = useCallback(async () => {

        try {
            toggleLoader(true);
            let getReportAPI = ""
            const reqData = {
                "YoyoLevel": level === "Select Level" ? null :level,
                "StartDate": startDate ? startDate : null,
                "VenueId": venue ? venue : 0,
                "EndDate": endDate ? endDate : null,
                "PageNumber": currentPage || 1
            }
            if (isReport) {
                if (isReport === "GetCultOnGroundDataList") {
                    getReportAPI = await GetCultOnGroundDataList(reqData)
                } else if (isReport === "GetOnGroundDataList") {
                    getReportAPI = await GetOnGroundDataList(reqData)
                } else if (isReport === "GetEmployeeOnGroundDataList") {
                    getReportAPI = await GetEmployeeOnGroundDataList(reqData)
                } else if (isReport === "GetFitbitDataList") {
                    getReportAPI = await GetFitbitDataList(reqData)
                } else if (isReport === "GetYoyoticketDataList") {
                    getReportAPI = await GetYoyoticketDataList(reqData)
                }
            }

            if (getReportAPI) {
                const res = getReportAPI;
                if (res.status === 200) {
                    const reportDetails = res?.data?.Data;
                    const reportData = reportDetails && reportDetails?.DataList
                    const totalCount = reportDetails && reportDetails?.TotalCount
                    console.log(reportDetails, 'reportDetails');
                    setTableData(reportData);
                    setTotalCount(totalCount);
                }
            }
        } catch (err) {
             setTableData([])
            console.log(err, 'report err');
            // Handle error
        } finally {
            toggleLoader(false);
        }
    }, [level, report, startDate, venue, endDate, currentPage]);

    const fetchVenuList = useCallback(async () => {
        toggleLoader(true);
        try {
            let getVenueAPI = ""
            if (isReport === "GetCultOnGroundDataList") {
                getVenueAPI = await GetCultfitVenue()
            } else if (isReport === "GetOnGroundDataList") {
                getVenueAPI = await GetOngroundVenue()
            }
            console.log(getVenueAPI, "getVenueAPI")
            const res = getVenueAPI;
            console.log(res, "venueee")
            if (res.status === 200) {
                const venuData = res?.data?.Data;
                console.log(venuData, "venuData")
                if (venuData && venuData.length) {
                    let concatenatedArray = [];

                    venuData.forEach((v) => {
                        if (v.VenueList && v.VenueList.length) {
                            v.VenueList.forEach((venue) => {
                                const newObj = {
                                    label: venue.VenueName,
                                    value: venue.Id,
                                };
                                concatenatedArray.push(newObj);
                            });
                        }
                    });
                    setVenueListing(concatenatedArray);
                }
            }
        } catch (err) {
            console.log(err, 'err123');
            // Handle error
        } finally {
            toggleLoader(false);
        }
    }, [isReport]);
    useMemo(()=>{
        setCurrentPage(1)
        },[isReport,venue,level,startDate,endDate])

    useEffect(() => {
        fetchVenuList();
    }, [fetchVenuList]);
    useEffect(() => {
        getReportDetails();
    }, [getReportDetails]);

//reset the values
    // useEffect(() => {
    //     setValue("StartDate", "")
    //     setValue("EndDate", "")
    //     //  setCurrentPage(1)
    //     setValue("Venue", null)
    // }, [level, isReport])

    // useEffect(() => {
    //     setValue("StartDate", null)
    //     setValue("EndDate", null)
    //     setCurrentPage(1)
    // }, [venue])

    const filteredData = useMemo(() => {
        return tableData && tableData?.length && Array.isArray(tableData) && tableData?.filter((data) => {
            const filterByName = data?.RegisterName ? data?.RegisterName : data?.Name
            console.log(filterByName, "filterByName")
            return filterByName
                ?.toString()
                .toLowerCase()
                .match(search.toLowerCase());
        });
    }, [search, tableData]);

    const handlePageChange = page => {
        console.log(page,"pagepage")
        setCurrentPage(page);
    };

    const onSubmitForm = () => {
    }
    const handleReset = () => {
        reset()
        setValue("StartDate", "")
        setValue("EndDate", "")
        setValue("Venue", null)
        setCurrentPage(1)
    }

    console.log(currentPage,"currentPage")
    return (
        <>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <div className="d-flex justify-content-around">
                    <h3 className="modal-title modal-title-sm mt-10">{isReport || "NA"}</h3>
                    {
                        levelFilterEnable &&
                        <div className="form-group w-100 mt-8 mb-md-5" style={{ maxWidth: '258px' }}>
                            <p>Level</p>
                            <ReactSelectInput
                                control={control}
                                name="Level"
                                options={[
                                    { label: "Select Level", value: null },
                                    { label: 'Beginner', value: 'Beginner' },
                                    { label: 'Intermediate', value: 'Intermediate' },
                                    { label: 'Advanced', value: 'Advanced' },
                                ]}
                            />
                        </div>
                    }
                    <div className="form-group w-100 mt-8 mb-md-5" style={{ maxWidth: '230px' }}>
                        <p>Start Date</p>
                        <TextInput
                            control={control}
                            name="StartDate"
                            inputProps={{
                                placeholder: 'Date*',
                            }}
                            type="date"
                        />
                    </div>

                    <div className="form-group w-100 mt-8 mb-md-5" style={{ maxWidth: '230px' }}>
                        <p>End Date</p>
                        <TextInput
                            control={control}
                            name="EndDate"
                            inputProps={{
                                placeholder: 'Date*',
                            }}
                            type="date"
                        />
                    </div>
                    {
                        venuFilterEnable &&
                        <div className="form-group w-100 mt-8 mb-md-5" style={{ maxWidth: '230px' }}>
                            <p>Venue</p>
                            <ReactSelectInput
                                control={control}
                                name="Venue"
                                options={venueListing}
                                inputProps={{
                                    placeholder: 'Venue*',
                                }}
                            />
                        </div>
                    }
                    <Button size="sm"
                    className="form-group w-100 mt-16 mb-10" style={{ maxWidth: '100px',maxHeight:"70px" }}
                        onClick={() => handleReset()}
                    >
                        Reset
                    </Button>
                </div>

            </form>
            <ReactTable tableData={tableData} columns={reportColumn} filteredData={filteredData} handlePageChange={handlePageChange} currentPage={currentPage}
                setSearch={setSearch} pagination={true} onPageChange={handlePageChange} totalCount={totalCount} />
        </>
    );
};

export default ReportTableContent;
