/**
 * @constant CURRENT_YEAR
 * @description return current year in YYYY format
 */
export const CURRENT_YEAR = new Date().getFullYear();

/**
 * @constant
 * @description defined the FILE sizes
 */
export const FILE_SIZE = {
  // VIDEO_UPLOAD_SIZE: 5000000, // 5MB
  VIDEO_UPLOAD_SIZE: 500000000, // 500MB
  VIDEO_RECORD_SIZE: 500000000, // 500MB
};

export const SHUTTLES_COUNT = {
  Beginner: '02',
  Intermediate: '04',
  Advanced: '08',
};

export const VIDEO_TIME_DURATION = {
  Beginner: 24, // sec
  Intermediate: 46, // sec
  Advanced: 89, // sec
};

export const CLAIM_TYPE = {
  FIT_BIT: 'FIT_BIT',
  YOYO_TICKET: 'YOYO_TICKET',
};

export const PRIVACY_POLICY_URL =
  'https://017073-content-prod.s3.ap-south-1.amazonaws.com/TCCC_Privacy_Policy_India.pdf';

export const TERMS_AND_CONDITIONS_URL =
  'https://elasticbeanstalk-017254-ap-south-1-prod-static.s3.ap-south-1.amazonaws.com/LMSxICC23_TnCs_Combined+14Sep.pdf';

export const FAQ_URL =
  'https://elasticbeanstalk-017254-ap-south-1-prod-static.s3.ap-south-1.amazonaws.com/FAQs+Limca+Sportz+ICC+Promo+2023+(004)%5B58%5D.pdf';

export const RESTRICTED_STATE = 'Tamil Nadu';

export const ELIGIBLE_COUNTRY = 'India';

// const isCountrynotIndia = ipData && ipData.country !== ELIGABLE_COUNTRY
