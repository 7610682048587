/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setAdminDetails } from "../../../store/slices/common-slice";
import { Button } from "react-bootstrap";

function AdminNavLinks(props) {
    const { toggleNavbar } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isAdminLogin = useSelector((state) => (state?.common?.adminDetails))
    const handleRedirectTableReport = (report) => {
        navigate("/admin-reports")
        if (report) {
            dispatch(setAdminDetails({ adminReportRoute: report }))
        }
    }
    const logout = () => {
        dispatch(setAdminDetails({ adminDetails: null}))
        navigate("/admin")
    }
    return (
        <>
            <ul className="main-nav">
                <li className="main-nav-item" onClick={toggleNavbar}>
                    <Link to="/admin-leaderBoard" className="main-nav-link"
                        onClick={() => handleRedirectTableReport("GetCultOnGroundDataList")}
                    >
                        <img
                            className="main-nav-icon"
                            src="/assets/img/icons/home.svg"
                            alt="Home"
                        />
                        <span>CultOnGround Report</span>
                    </Link>
                </li>

                <li className="main-nav-item" onClick={toggleNavbar}>
                    <Link to="/admin-leaderBoard" className="main-nav-link"
                        onClick={() => handleRedirectTableReport("GetOnGroundDataList")}>
                        <img
                            className="main-nav-icon"
                            src="/assets/img/icons/login.svg"
                            alt="Login"
                        />
                        <span> OnGround Report</span>
                    </Link>
                </li>

                <li className="main-nav-item" onClick={toggleNavbar}>
                    <Link to="/admin-leaderBoard"
                        onClick={() => handleRedirectTableReport("GetEmployeeOnGroundDataList")}
                        className="main-nav-link">
                        <img
                            className="main-nav-icon"
                            src="/assets/img/icons/win.svg"
                            alt="Win trophy"
                        />
                        <span> EmployeeOnGround Report</span>
                    </Link>
                </li>

                <li className="main-nav-item" onClick={toggleNavbar}>
                    <Link to="/admin-leaderBoard" className="main-nav-link" onClick={() => handleRedirectTableReport("GetFitbitDataList")}>
                        <img
                            className="main-nav-icon"
                            src="/assets/img/icons/fitbit.svg"
                            alt="Fitness tracker"
                        />
                        <span> Fitbit Report</span>
                    </Link>
                </li>

                <li className="main-nav-item" onClick={toggleNavbar}>
                    <Link to="/admin-leaderBoard" className="main-nav-link" onClick={() => handleRedirectTableReport("GetYoyoticketDataList")}>
                        <img
                            className="main-nav-icon"
                            src="/assets/img/icons/yo-yo.svg"
                            alt="Yo yo test"
                        />
                        <span> Yoyoticket Report</span>
                    </Link>
                </li>
                {isAdminLogin?.adminDetails && (
                <li className="main-nav-item" onClick={toggleNavbar}>
                    <div className="devider mb-14">
                        <Button
                            className="btn-primary"
                            size="sm"
                            onClick={logout}
                        >
                            LOGOUT
                        </Button>
                    </div>
                </li>
                 )}
            </ul>
        </>
    );
}

export default AdminNavLinks;
