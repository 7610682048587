import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";

import { getUserClaimTimeById } from "../../services/fitness-tracker-service";
import useLoader from "../../utils/hooks/use-loader";
import AppModal from "../../components/modals";
import CountdownTimer from "../../components/common/countdown-timer";
import usePopup from "../../utils/hooks/use-popup";
import { CLAIM_TYPE } from "../../utils/constants/common-constants";
import { onLoadCDP } from "../../utils/helpers/cds-helper";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../utils/helpers/floodlight-helper";

function UniqueCodeSuccess({ show, onClose }) {
  const { toggleLoader } = useLoader();
  const { openClaimPopup } = usePopup();

  const userDetails = useSelector((state) => state.auth.userDetails);

  const [winTime, setWinTime] = useState("");

  const fetchClaimTime = useCallback(async () => {
    toggleLoader(true);
    try {
      const reqData = {
        Id: userDetails?.Id,
      };
      const res = await getUserClaimTimeById(reqData);
      if (res.status === 200) {
        const data = res.data?.Data;
        if (data) {
          const time = `${data.Date}T${data.Time}Z`;
          setWinTime(time);
        }
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  }, [userDetails?.Id, toggleLoader]);

  useEffect(() => {
    if (show) {
      onLoadCDP("Win_FitnessTracker");
    }
  }, [show]);

  useEffect(() => {
    fetchClaimTime();
  }, [fetchClaimTime]);

  const handleClaim = () => {
    counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_ClaimNow_Counter);
    onClose();
    window.dataLayer.push({ event: "lmsuniquecodeclaimnow" });
    setTimeout(() => {
      openClaimPopup(CLAIM_TYPE.FIT_BIT)(true);
    }, 100);
  };

  return (
    <AppModal
      show={show}
      onClose={onClose}
      heading="Congratulations!"
      subHeading="You have won a Fitness Tracker!"
    >
      <>
        <img
          src="/assets/img/products/ft.svg"
          alt="Fitness Tracker"
          className="mt-n4"
        />
        <CountdownTimer winTime={winTime} />
        <p className="mb-4">Time Left To Claim</p>
        <Button className="btn btn-primary btn-block" onClick={handleClaim}>
          Claim Now
        </Button>
      </>
    </AppModal>
  );
}

export default UniqueCodeSuccess;
