import React from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { MetaPixel } from '../../../utils/helpers/metaHelper';

function NotParticipated() {
  const navigate = useNavigate();

  return (
    <>
      <Card.Title as="h3">GET READY!</Card.Title>
      <p className="text-capitalize">
        Take the #YoYoTest for #LimcaSportz & get <br />a chance to win ICC
        Men's Cricket World <br />
        Cup 2023 tickets*
      </p>
      <img
        src="/assets/img/common/getReadyneeraj.png"
        alt="Neeraj"
        className="mt-5 mt-sm-13 mb-3 mb-sm-7"
      />
      <button
        className="btn btn-primary d-flex mx-auto"
        onClick={() => {
          MetaPixel('ParticipateNow');
          navigate('/yoyo');
        }}
        >
        Participate Now
      </button>
    </>
  );
}

export default NotParticipated;
