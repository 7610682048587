import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card } from "react-bootstrap";

import useLoader from "../../../utils/hooks/use-loader";
import useAuth from "../../../utils/hooks/use-auth";
import { getYoYoTestById } from "../../../services/yoyo-service";
import ScoreCard from "./score-card";
import InProgress from "./in-progress";
import NotShortlisted from "./not-shortlisted";
import Shortlisted from "./shortlisted";
import WonYoYo from "./won";
import NotParticipated from "./not-participated";
import { setCDP } from "../../../store/slices/common-slice";
import { onLoadCDP } from "../../../utils/helpers/cds-helper";

function YoYoWinning() {
  const dispatch = useDispatch();

  const { userDetails } = useAuth();
  const { toggleLoader, isLoading } = useLoader();
  const [cardToggle, setCardToggle] = useState(false);
  const [yoyoData, setYoYoData] = useState(null);
  const [CurrentStatus, setCurrentStatus] = useState("");

  const cdpCache = useSelector((state) => state.common.cdpCache);
  const isShortListed = userDetails && userDetails?.IsShortlisted
  const isRejected = userDetails && userDetails?.IsRejected
  console.log(isRejected ,"status",isShortListed)

  const fetchYoYoTestById = useCallback(async () => {
    toggleLoader(true);
    try {
      const reqData = {
        Id: userDetails?.Id,
      };
      const res = await getYoYoTestById(reqData);
      if (res.status === 200) {
        const data = res.data?.Data;
        console.log("data: ", data);
        if (data) {
          setYoYoData(data);
          setCurrentStatus(data.CurrentStatus);


          if (cdpCache?.yoyo && cdpCache?.yoyo !== data?.CurrentStatus) {
            const eventSubType = {
              Shortlisted: "Yo_Yo_Entry_Shortlisted",
              Rejected: "Yo_Yo_Entry_Rejected_Time Mismatch",
              Winner: "Win_ICCTicket",
            };
            if (eventSubType[data?.CurrentStatus]) {
              console.log(
                "eventSubType[data?.CurrentStatus]: ",
                eventSubType[data?.CurrentStatus]
              );
              onLoadCDP(eventSubType[data?.CurrentStatus]);
            }
          }
          dispatch(setCDP({ yoyo: data?.CurrentStatus }));
        }
      }
    } catch (err) {
      console.log("err: ", err);
    } finally {
      toggleLoader(false);
    }
  }, [dispatch, userDetails?.Id]);

  useEffect(() => {
    fetchYoYoTestById();
  }, [fetchYoYoTestById]);

  function handleToggle() {
    if (cardToggle) {
      setCardToggle(false);
    } else {
      setCardToggle(true);
    }
  }

  /**
   * @method renderScreens
   * @description render different screens based on current status
   * @returns
   */
  const renderScreens = () => {
    if (!yoyoData) return <NotParticipated />;
    if (CurrentStatus === "InProgress") {
      return <InProgress />;
    } else if (CurrentStatus === "Rejected" || isRejected) {
      return <NotShortlisted />;
    } else if (CurrentStatus === "Shortlisted" || isShortListed) {
      return <Shortlisted />;
    } else if (CurrentStatus === "Winner") {
      return <WonYoYo />;
    }

    return <NotParticipated />;
  };

  if (isLoading) {
    return (
      <Card className="yo-yo-card bg-blur">
        <Card.Body className="px-7 d-flex align-items-center flex-column justify-content-center">
          <Card.Title>
            <h3 className="mt-1 text-uppercase">Loading..</h3>
          </Card.Title>

          <Card.Subtitle className="mb-3 text-capitalize px-5">
            Please wait!
          </Card.Subtitle>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card className="bg-blur">
        {yoyoData?.Id ? (
          <div className="score-wrap">
            <Button className="score-btn" onClick={handleToggle}>
              {yoyoData && cardToggle ? (
                <img src="/assets/img/icons/close-icon.svg" alt="Close" />
              ) : (
                <span>
                  My
                  <br />
                  Score
                  <br />
                  Card
                </span>
              )}
            </Button>
          </div>
        ) : null}
        <Card.Body>{renderScreens()}</Card.Body>
        {yoyoData?.Id && (
          <ScoreCard cardToggle={cardToggle} yoyoData={yoyoData} />
        )}
      </Card>
    </>
  );
}

export default YoYoWinning;
