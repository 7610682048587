import React from "react";
import DataTable from "react-data-table-component";

const ReactTable = (props) => {
    const {tableData,columns,filteredData,setSearch,totalCount,handlePageChange,currentPage } = props
    console.log(tableData,"tableData123",currentPage)
    return (
        <>
        <div className="table-container">
               {tableData ? (
                <DataTable
                    columns={columns}
                    //if we want to render all over the data without filteration
                    // data={tableData}
                    //if we want data with filter
                    data={filteredData}
                    className="icc-table"
                    pagination
                    paginationServer
                    paginationTotalRows={totalCount}
                    paginationDefaultPage={1}
                    onChangePage={handlePageChange}
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                      }}
                    // fixedHeader
                    // fixedHeaderScrollHeight="570px"
                    highlightOnHover
                    subHeader
                    striped
                    paginationDisabled={currentPage === 1}
                    subHeaderComponent={
                        <input
                            type="text"
                            placeholder="Search Name"
                            className="w-25 form-control"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    }
                />
            ) : (
                "No Data Found"
            )}
        </div>
         
        </>
    )
}

export default ReactTable