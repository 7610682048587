import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";

function PublicLayout() {
  const { pathname } = useLocation();
  console.log("pathname: ", pathname);
  return (
    <div>
      <Header />
      <main
        role="main"
        className={`main ${
          pathname === "/" ? "bg-pattern-3" : "bg-pattern-2"
        } `}
      >
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default PublicLayout;
