import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";

import Login from "./login";
import VerifyOTP from "./verify-otp";
import AppModal from "../../components/modals";
import SignUp from "./sign-up";
import { loginSuccess, logoutUser } from "../../store/slices/auth-slice";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../utils/helpers/floodlight-helper";
import {setSmsLinkRedirection} from '../../store/slices/common-slice';

function Auth({ show, onClose }) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const [sendOtpSuccess, setSendOtpSuccess] = useState(false);
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [MobileNo, setMobileNo] = useState("");
  const smsLinkRedirection = useSelector((state) => state?.common?.smsLinkRedirection)

  useEffect(() => {
    // if there is no mobile number then always stay on login
    if (!MobileNo) {
      setSendOtpSuccess(false);
      setOtpSuccess(false);
    }
  }, [MobileNo]);

  useEffect(() => {
    return () => {
      setSendOtpSuccess(false);
      setOtpSuccess(false);
    };
  }, []);

  const handleClose = () => {
    // dispatch(setSmsLinkRedirection({ popup: "" }));
    onClose();
    // reset state on close to open enter mobile again
    setSendOtpSuccess(false);
    setOtpSuccess(false);
  };

  const handleLoginSuccess = (data) => {
    if (data?.Id) {
      // on success set user data in redux store
      dispatch(loginSuccess(data));
      handleClose();
      dispatch(setSmsLinkRedirection({ popup: smsLinkRedirection.popup, loginJourney: true }))
      // counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_Login_Counter);
    }
  };

  const handleOTPSuccess = (data) => {
    if (data?.Id) {
      // if there is Id then user in not first time user
      return handleLoginSuccess(data);
    }
    setOtpSuccess(true);
  };

  const renderContent = () => {
    if (sendOtpSuccess) {
      return <VerifyOTP MobileNo={MobileNo} onSuccess={handleOTPSuccess} />;
    }
    return (
      <div>
        <Login
          setSendOtpSuccess={setSendOtpSuccess}
          setMobileNo={setMobileNo}
        />
      </div>
    );
  };

  if (!show) return null;

  if (isLoggedIn) {
    // if already logged in close the modal
    // for now just displayed a message
    return (
      <AppModal show={show} onClose={onClose} heading="You are logged In">
        <Button onClick={() => dispatch(logoutUser())} className="my-5">
          Logout
        </Button>
      </AppModal>
    );
  }

  if (otpSuccess) {
    return (
      <AppModal
        show={show}
        heading="You're almost there!"
        subHeading="Sign up to proceed"
        onClose={handleClose}
      >
        <SignUp onSuccess={handleLoginSuccess} MobileNo={MobileNo} />
      </AppModal>
    );
  }

  return (
    <AppModal
      show={show}
      onClose={handleClose}
      heading="Welcome Guest!"
      subHeading="Login to get started"
    >
      {renderContent()}
    </AppModal>
  );
}

export default Auth;
