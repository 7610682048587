import React from "react";
import { Navigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import WinnersList from "../features/winners/winners-list";
import useAuth from "../utils/hooks/use-auth";

function WinnerPage() {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <div className="container-fluid">
        <WinnersList />
      </div>
    </>
  );
}

export default WinnerPage;
