import Cookies from "js-cookie";
import { store } from "../store";
import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";

export const verifyUniqueCode = async (data) => {
  const Region = store.getState().common.ipDetails.region;

  return await AxiosInstance.post(API_END_POINT.verifyUniqueCode, {
    ...data,
    Region,
    HfSession: Cookies?.get("__cds_pixel_id"),
  });
};

export const getUserClaimTimeById = async (data) => {
  return await AxiosInstance.post(API_END_POINT.getUserClaimTimeById, data);
};

export const claimNowFitnessTracker = async (data) => {
  const ipDetails = store.getState().common.ipDetails;
  const reqData = {
    ...data,
    GeoPinCode: ipDetails?.zip,
    HfSession: Cookies?.get("__cds_pixel_id"),
  };
  return await AxiosInstance.post(
    API_END_POINT.claimNowFitnessTracker,
    reqData
  );
};

export const checkQuota = async (data) => {
  return await AxiosInstance.post(API_END_POINT.checkQuota, data);
};

export const pullUniqueCode = async (data) => {
  const reqData = {
    ...data,
    HfSession: Cookies?.get("__cds_pixel_id"),
  };
  return await AxiosInstance.post(API_END_POINT.pullUniqueCode, reqData);
};
