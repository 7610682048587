import Cookies from 'js-cookie';
import { AxiosInstance } from '../utils/axios-helper/axios-helper';
import { API_END_POINT } from '../utils/constants/api-end-points';
import { store } from '../store';

export const registerYoYoTest = async (data) => {
  const reqData = {
    ...data,
    HfSession: Cookies?.get('__cds_pixel_id'),
  };
  return await AxiosInstance.post(API_END_POINT.registerYoYoTest, reqData);
};

export const getYoYoTestById = async (data) => {
  return await AxiosInstance.post(API_END_POINT.getYoYoTestById, data);
};

export const generateARCertificate = async (data) => {
  const reqData = {
    ...data,
    HfSession: Cookies?.get('__cds_pixel_id'),
  };
  return await AxiosInstance.post(API_END_POINT.generateARCertificate, reqData);
};

export const claimNowYoYoTicket = async (data) => {
  const ipDetails = store.getState().common.ipDetails;
  const reqData = {
    ...data,
    GeoPinCode: ipDetails?.zip,
    HfSession: Cookies?.get('__cds_pixel_id'),
  };
  return await AxiosInstance.post(API_END_POINT.claimNowYoYoTicket, reqData);
};

export const registerOnGroundYoYoTest = async (data) => {
  return await AxiosInstance.post(API_END_POINT.registerOnGroundYoYoTest, data);
};

export const registerOnCollegeYoYoTest = async (data) => {
  return await AxiosInstance.post(
    API_END_POINT.registerOnCollegeYoYoTest,
    data
  );
};

export const registerOnCultfitOnGround = async (data) => {
  return await AxiosInstance.post(
    API_END_POINT.registerOnCultfitOnGround,
    data
  );
};
