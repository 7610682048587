import React from 'react';
import { Card } from 'react-bootstrap';
import usePopup from '../../../utils/hooks/use-popup';
import useAuth from '../../../utils/hooks/use-auth';
import { CLAIM_TYPE } from '../../../utils/constants/common-constants';

function WonYoYo() {
  const { openClaimPopup } = usePopup();
  const { userDetails } = useAuth();
  return (
    <>
      <Card.Title as="h3">
        Congrats, You've won a ticket to the ICC Men's Cricket World Cup 2023!
      </Card.Title>
      <img
        src="/assets/img/products/ticket.png"
        alt="Ticket"
        className="my-5 my-sm-8 ticket-img"
      />
      {!userDetails?.IsYoYoTicketClaim && (
        <button
          className="btn btn-primary d-flex mx-auto"
          onClick={openClaimPopup(CLAIM_TYPE.YOYO_TICKET)}>
          Claim Now
        </button>
      )}
    </>
  );
}

export default WonYoYo;
