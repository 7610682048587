import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ActivityPage() {
  return (
    <>
      <div className="container-fluid">
        <Row className="justify-content-center text-center">
          <Col xs={12} className="mt-10 mt-sm-14 pt-sm-4">
            <h1>You just missed it!</h1>
            <p>
              The current activity is officially over. You can <br />
              continue to view the winner list.
            </p>
            <Link className="btn btn-primary mt-6">Winners List</Link>
          </Col>
        </Row>
      </div>
      <div className="track track-border-bottom mt-5 mt-md-0">
        <img
          className="track-cone track-cone-left"
          src="/assets/img/icons/road-cone.svg"
          alt="Road cone"
        />
        <img
          className="track-runner track-runner-right"
          src="/assets/img/common/runner.svg"
          alt="runner"
        />
        <img
          className="track-cone track-cone-right"
          src="/assets/img/icons/road-cone.svg"
          alt="Road cone"
        />
      </div>
      <div className="container-fluid">
        <Row className="justify-content-center text-center">
          <Col xs={12} sm={10} md={8} className="mt-10 mt-sm-14 pt-sm-4">
            <div className="bg-blur py-14">
              <h1 className="mb-12">
                Don’t fret, exciting challenges <br />& more coming your way!
              </h1>
              <p>Follow us on social</p>
              <div className="social-link mt-8 mb-8 mb-md-14 pb-md-2">
                <Link>
                  <img
                    className="icon"
                    src="/assets/img/social-media/facebook.svg"
                    alt="Ticket"
                  />
                </Link>
                <Link>
                  <img
                    className="icon"
                    src="/assets/img/social-media/instagram.svg"
                    alt="Ticket"
                  />
                </Link>
                <Link>
                  <img
                    className="icon"
                    src="/assets/img/social-media/twitter.svg"
                    alt="Ticket"
                  />
                </Link>
                <Link>
                  <img
                    className="icon"
                    src="/assets/img/social-media/linkedin.svg"
                    alt="Ticket"
                  />
                </Link>
                <Link>
                  <img
                    className="icon"
                    src="/assets/img/social-media/whatsapp.svg"
                    alt="Ticket"
                  />
                </Link>
              </div>
              <p className="mb-6">Rehydrate and recharge with #LimcaSportz</p>
              <Link className="btn btn-primary">Buy online Now</Link>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ActivityPage;
