import React, { useEffect, useState } from "react";

import FieldForm from "./field-form";
import useAuth from "../../utils/hooks/use-auth";
import { updateUserEmail, updateUserName } from "../../services/user-service";
import {
  emailValidationSchema,
  mobileNoValidationSchema,
  nameValidationSchema,
} from "../../utils/validations";
import ProfileImageForm from "./profile-image-form";
import { handleKeyPressName, maskMobileNo } from "../../utils/helpers";
import { Link } from "react-router-dom";
import { PRIVACY_POLICY_URL } from "../../utils/constants";
import { FLOODLIGHT_COUNTER, counterFloodLight } from "../../utils/helpers/floodlight-helper";
import { TextInput } from "../../utils/form-inputs";

function ProfileForm({ open }) {
  const { userDetails } = useAuth();
  const [edit, setEdit] = useState([""]);

  useEffect(() => {
    if (!open) {
      setEdit([]);
    }
  }, [open]);
console.log(userDetails.TotalYoyoAttempt,"userDetails123")
  return (
    <div className="profile-wrapper form">
      <ProfileImageForm />
      <FieldForm
        Id={userDetails?.Id}
        fieldName="MobileNo"
        initialValue={{ MobileNo: userDetails?.MobileNo }}
        edit={edit}
        setEdit={setEdit}
        editing={edit.includes("MobileNo")}
        validationSchema={mobileNoValidationSchema}
        canNotEdit
      />
      <FieldForm
        Id={userDetails?.Id}
        fieldName="Email"
        initialValue={{ Email: userDetails?.Email }}
        edit={edit}
        setEdit={setEdit}
        editing={edit.includes("Email")}
        API={updateUserEmail}
        validationSchema={emailValidationSchema}
      />
      <FieldForm
        Id={userDetails?.Id}
        fieldName="Name"
        initialValue={{ Name: userDetails?.Name }}
        edit={edit}
        setEdit={setEdit}
        editing={edit.includes("Name")}
        API={updateUserName}
        validationSchema={nameValidationSchema}
        onInput={handleKeyPressName}
      />
      {console.log(userDetails.TotalYoyoAttempt,"TotalYoyoAttempt11")}
      <FieldForm
        Id={userDetails?.Id}
        fieldName="TotalYoyoAttempt"
        label="Total Yoyo Attempt"
        initialValue={{ TotalYoyoAttempt: userDetails && userDetails.TotalYoyoAttempt}}
        edit={edit}
        setEdit={setEdit}
        editing={edit.includes("TotalYoyoAttempt")}
        canNotEdit
      />

      <div className="d-flex justify-content-center pt-5">
        <Link
          className="d-flex justify-content-center"
          to={PRIVACY_POLICY_URL}
          target={"_blank"}
          onClick={() => {
            counterFloodLight(FLOODLIGHT_COUNTER.LimcaSportz_ICC23_PrivacyPolicy_Counter);
          }}
        >
          Privacy Policy
        </Link>
      </div>
    </div>
  );
}

export default ProfileForm;
